import gql from 'graphql-tag';

export const SAVE_INTEGRATION_SETTINGS = gql`
  mutation SaveIntegrationSettings(
    $id: ID!
    $syncOnlyUsedAccounts: Boolean!
    $syncOnlyUsedContacts: Boolean!
  ) {
    updateintegration(
      id: $id
      data: {
        syncOnlyUsedAccounts: $syncOnlyUsedAccounts
        syncOnlyUsedContacts: $syncOnlyUsedContacts
      }
    ) {
      _id
    }
  }
`;

export const SAVE_INTEGRATION_DNC_REPORT = gql`
  mutation SaveIntegrationSettings($id: ID!, $dncReportId: String!) {
    updateintegration(id: $id, data: { dncReportId: $dncReportId }) {
      _id
    }
  }
`;

export const SAVE_INTEGRATION_OPT_OUT_REPORT = gql`
  mutation SaveIntegrationSettings($id: ID!, $optOutReportId: String!) {
    updateintegration(id: $id, data: { optOutReportId: $optOutReportId }) {
      _id
    }
  }
`;

export const SAVE_INTEGRATION_FIELDS = gql`
  mutation SaveIntegrationFields(
    $id: ID!
    $selectedAccountFields: [String!]
    $selectedContactFields: [String!]
    $selectedOpportunityFields: [String!]
    $selectedLeadFields: [String!]
  ) {
    updateintegration(
      id: $id
      data: {
        selectedAccountFields: $selectedAccountFields
        selectedContactFields: $selectedContactFields
        selectedOpportunityFields: $selectedOpportunityFields
        selectedLeadFields: $selectedLeadFields
      }
    ) {
      _id
    }
  }
`;

export const RUN_INTEGRATION_SETUP = gql`
  mutation SalesforceIntegrationSetup {
    salesforceIntegrationSetup
  }
`;

export const DELETE_DNC_FILTER_CRITERIA = gql`
  mutation deleteFilterCriteria($id: ID!) {
    deletedncFilterCriteria(id: $id)
  }
`;

export const SAVE_DNC_FILTER_CRITERIA = gql`
  mutation createOrUpdateFilter(
    $type: String
    $operator: String
    $createdBy: ID!
    $criteria: [DncFilterCriteriaCreateDTO!]!
  ) {
    createDncFilter(
      dncFilterData: {
        recordType: $type
        logicalOperator: $operator
        createdBy: $createdBy
      }
      dncFilterCriteriaDatas: $criteria
    )
  }
`;

export const UPDATE_DNC_FILTER = gql`
  mutation createOrUpdateFilter($id: ID!, $operator: String) {
    updateDncFilter(dncFilterData: { _id: $id, logicalOperator: $operator })
  }
`;

export const UPDATE_CRITERIA = gql`
  mutation createOrUpdateFilter($criteria: [DncFilterCriteriaUpdateDTO!]!) {
    updateDncFilter(dncFilterCriteriaDatas: $criteria)
  }
`;

export const UPDATE_DNC_FILTER_CRITERIA = gql`
  mutation createOrUpdateFilter(
    $id: ID!
    $operator: String
    $criteria: [DncFilterCriteriaUpdateDTO!]!
  ) {
    updateDncFilter(
      dncFilterData: { logicalOperator: $operator, _id: $id }
      dncFilterCriteriaDatas: $criteria
    )
  }
`;
export const CREATE_FIELD_QUERY = gql`
  mutation createCustomField(
    $label: String!
    $dataType: String!
    $controlType: String!
    $recordType: String!
    $fieldDropDownValues: [String!]
  ) {
    createCustomField(
      data: {
        label: $label
        dataType: $dataType
        controlType: $controlType
        recordType: $recordType
        fieldDropDownValues: $fieldDropDownValues
      }
    ) {
      _id
    }
  }
`;

export const DELETE_FIELD_QUERY = gql`
  mutation deleteField($id: ID!) {
    deletefield(id: $id)
  }
`;

export const UPDATE_FIELD_MAPPINGS_QUERY = gql`
  mutation updateAllFieldMappings($args: [FieldMappingArgs!]!) {
    updateAllFieldMappings(args: $args)
  }
`;

export const UPDATE_FIELD_QUERY = gql`
  mutation UpdateField($id: ID!, $label: String!) {
    updatefield(id: $id, data: { label: $label }) {
      _id
    }
  }
`;

export const DELETE_FIELD_DROPDOWN_VALUE = gql`
  mutation DeleteFieldDropDownValue($id: ID!) {
    deletefieldDropDownValue(id: $id)
  }
`;

export const INSERT_DROPDOWN_FIELD = gql`
  mutation createfieldDropDownValue($name: String!, $fieldId: ID!) {
    createfieldDropDownValue(data: { name: $name, fieldId: $fieldId }) {
      _id
    }
  }
`;

export const UPDATE_MULTIPLE_DROPDOWN_VALUE = gql`
  mutation updateMultipleDropDownValues($data: [FieldDropdownValueArgs!]!) {
    updateMultipleDropDownValues(data: $data) {
      _id
    }
  }
`;

export default SAVE_INTEGRATION_SETTINGS;
