import gql from 'graphql-tag';

export const CONTACTS_QUERY = gql`
  query v3_customer_contacts($filter: JSON, $limit: Int, $skip: Int) {
    contacts(limit: $limit, skip: $skip, filter: $filter) {
      _id
      avatarsValue
      sender {
        fullName
      }
      customerAccount {
        _id
        nameValue
        salesforceIdsValue
      }
      salesforceIdsValue
      lastApproachedAt
      lastContactedAt
      familyNameValue
      givenNameValue
      linkedinUrlsValue
      position {
        _id
        email
        title
      }
      status
    }
    contactsMeta(filter: $filter) {
      count
    }
  }
`;
