import gql from 'graphql-tag';

const DELETE_INTEGRATION = gql`
  mutation DeleteIntegration($id: ID!) {
    deleteintegration(id: $id)
    removeIdentity(integrationId: $id)
  }
`;

export const DELETE_INTEGRATION_CADENCE = gql`
  mutation DeleteIntegration($id: ID!) {
    deleteCadenceIntegration(id: $id)
  }
`;

export default DELETE_INTEGRATION;
