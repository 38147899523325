import gql from 'graphql-tag';

export const CREATE_AAL = gql`
  mutation createAAL($name: String) {
    createAccountAssignmentLogic(data: { name: $name }) {
      _id
    }
  }
`;

export const UPDATE_AAL = gql`
  mutation updateAAL($id: ID!, $name: String, $senderIds: [ID!]) {
    updateaccountAssignmentLogic(
      id: $id
      data: { name: $name, senderIds: $senderIds }
    ) {
      _id
    }
  }
`;

export const DELETE_AAL = gql`
  mutation deleteAAL($id: ID!) {
    deleteaccountAssignmentLogic(id: $id)
  }
`;

export default CREATE_AAL;
