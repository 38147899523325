// @ts-nocheck
import { useCallback, useEffect, useRef } from 'react';

export function useTimeout(callback: () => void, delay: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef();

  // Everytime callback changes we are updating callback referance
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Everytime the delay changes we are resetting the timeout
  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  // This function is used to clear the timeout
  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  // Everytime delay, set, clear changes we are clearing the existing timeout and reset it again
  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  // This function clears the timeout and reset it
  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
}
