import React from 'react';
import { ErrorLinks, KoncertLogodiv } from '@koncert/shared-components';

export const TimeoutError = (props) => (
  <div className="d-flex flex-column justify-content-center min-vh-100">
    <div className="text-center mb-4">
      <div className="mb-3">
        <KoncertLogodiv customWidth="350" />
      </div>
      <div className="text-lg mb-3">
        <i className="fa fa-wrench text-muted mr-2"></i>
        500
      </div>
      <p className="lead m-0">
        Application is taking more time than expected. Please try again after
        sometime or please contact{' '}
        <a href="mailto:support@koncert.com">Koncert Support</a>.
      </p>
    </div>
    <ErrorLinks props={props} gotoApp={true} />
  </div>
);

export default TimeoutError;
