import gql from 'graphql-tag';

export const INTEGRATIONS_QUERY = gql`
  query v3_Customer_Integrations($limit: Int = 10, $skip: Int = 0) {
    integrations(limit: $limit, skip: $skip) {
      _id
      importProgress
      customerId
      createdAt
      updatedAt
      name
      category
      provider
      authenticated
      protectedIdentities {
        _id
        createdAt
        updatedAt
        nickname
        email
        instanceUrl
        userId
      }
      defaultSender {
        _id
        fullName
        imageUrl
      }
      integratedUsers
    }
  }
`;
export const SYNC_SALESFORCE_INTEGRATION_QUERY = gql`
  query syncSalesforceIntegration {
    syncSalesforceIntegration
  }
`;

export default INTEGRATIONS_QUERY;
