import { useEffect } from 'react';
import { useTimeout } from './useTimeout';

// This hook is used to call the callback function after a certain delay whenever its dependencies changed
export function useDebounce(
  callback: () => void,
  delay: number,
  dependencies: string[]
) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(clear, []);
}
