import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Loading from './components/Layout/Loading';

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
