import gql from 'graphql-tag';

export const STORYQUERYSTRING = gql`
  query($storyId: ID!) {
    story(where: { _id: $storyId }) {
      _id
      name
      priority
      customerId
      rulesOfEngagementId
      accountSelectorId
      contactSelectorId
      sendingWindowDayStart
      sendingWindowDayEnd
      sendingWindowHourStart
      sendingWindowHourEnd
      roeBase {
        _id
        name
        days
      }
      accountSelector {
        _id
        name
      }
      contactSelector {
        _id
        name
      }
      messages {
        _id
        name
        position
        plotPoints {
          _id
          name
          position
          plotPointCategoryId
          defaultElement {
            _id
            weight
            text
            type
            senderId
            logicalOperator
            triggerDataPoints {
              _id
              value
              _type
            }
          }
          additionalElements {
            _id
            weight
            text
            logicalOperator
            type
            senderId
            triggerDataPoints {
              _id
              value
              _type
            }
          }
        }
      }
    }
    plotPointsMeta(where: { _id: $storyId }) {
      count
    }
  }
`;
