import gql from 'graphql-tag';

export const SYSTEMJOURNALAGGREGATION = gql`
  query systemJournalAggregation(
    $format: String = "day"
    $startDate: DateTime
    $endDate: DateTime
  ) {
    systemJournalAggregations(
      format: $format
      startDate: $startDate
      endDate: $endDate
    ) {
      _id
      startDate
      endDate
      data
    }
  }
`;

export default SYSTEMJOURNALAGGREGATION;
