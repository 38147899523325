import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from 'reactstrap';
import { useQuery, useMutation } from '@apollo/client';
import SpinnerButton from '../Extras/SpinnerButton';
import {
  CREATE_BASIC_STORY,
  CREATE_SIMPLE_STORY,
  CREATE_OLD_CUSTOMER_NEW_JOB_STORY,
  CREATE_DEMO_STORY,
  CREATE_CLOSED_LOST_STORY,
  CREATE_OPEN_OPPS_NO_ACTIVITY_STORY,
  UPDATE_STORY,
  CONTACTSELECTORS_QUERY,
  ACCOUNTSELECTORS_QUERY,
  ROE_QUERY,
} from '@koncert/graphql';
import { sortingByProperty } from '@koncert/shared-components';

import { useTracking } from '../SegmentTracker';
import { useForm, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { name } from '../Forms/FormValidatorPattern';
import { withRouter } from 'react-router-dom';

const StoryEditor = ({ customerId, story, history }) => {
  const { handleSubmit, register, errors, control } = useForm({});
  const tracker = useTracking();
  const [createBasicStory, { loading: createStoryLoading }] = useMutation(
    CREATE_BASIC_STORY
  );
  const [
    createSimpleStory,
    { loading: createSimpleStoryLoading },
  ] = useMutation(CREATE_SIMPLE_STORY);
  const [
    createClosedLostStory,
    { loading: createClosedLostStoryLoading },
  ] = useMutation(CREATE_CLOSED_LOST_STORY);
  const [
    createOpenOppsNoActivityStory,
    { loading: createOpenOppsNoActivityStoryLoading },
  ] = useMutation(CREATE_OPEN_OPPS_NO_ACTIVITY_STORY);
  const [
    createOldCustomerNewJobStory,
    { loading: createOldCustomerNewJobStoryLoading },
  ] = useMutation(CREATE_OLD_CUSTOMER_NEW_JOB_STORY);
  const [createDemoStory, { loading: createDemoLoading }] = useMutation(
    CREATE_DEMO_STORY
  );
  const [updateStory, { loading: updateStoryLoading }] = useMutation(
    UPDATE_STORY
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const storyType = useWatch({
    control,
    name: 'storyType',
    defaultValue: story.storyType,
  });

  const [showAdvanced, toggleAdvanced] = useState(
    story.storyType === 'V3::Customer::Stories::Basic'
  );

  const onSubmit = ({
    name,
    priority,
    storyType,
    accountSelectorId,
    contactSelectorId,
    rulesOfEngagementId,
    sendingWindowDayStart,
    sendingWindowDayEnd,
    sendingWindowHourStart,
    sendingWindowHourEnd,
  }) => {
    if (story._id) {
      tracker.track('Edit Story Clicked');

      updateStory({
        variables: {
          id: story._id,
          storyType: storyType,
          name: name,
          priority: parseInt(priority),
          accountSelectorId: accountSelectorId,
          contactSelectorId: contactSelectorId,
          rulesOfEngagementId: rulesOfEngagementId,
          sendingWindowDayStart: parseInt(sendingWindowDayStart),
          sendingWindowDayEnd: parseInt(sendingWindowDayEnd),
          sendingWindowHourStart: parseInt(sendingWindowHourStart),
          sendingWindowHourEnd: parseInt(sendingWindowHourEnd),
        },
        refetchQueries: ['v3_Customer_Stories'],
      }).then((data) => {
        tracker.track('Story Edited');
        history.push(`/stories/${story?._id}`);
      });
    } else {
      tracker.track('Create Story Clicked');
      if (storyType === 'V3::Customer::Stories::Basic') {
        createBasicStory({
          variables: {
            customerId: customerId,
            name: name,
            priority: parseInt(priority),
            accountSelectorId: accountSelectorId,
            contactSelectorId: contactSelectorId,
            rulesOfEngagementId: rulesOfEngagementId,
            sendingWindowDayStart: parseInt(sendingWindowDayStart),
            sendingWindowDayEnd: parseInt(sendingWindowDayEnd),
            sendingWindowHourStart: parseInt(sendingWindowHourStart),
            sendingWindowHourEnd: parseInt(sendingWindowHourEnd),
            pausedAt: new Date(),
          },
          refetchQueries: ['v3_Customer_Stories'],
        }).then((data) => {
          tracker.track('Basic Story Created');
          history.push('/stories');
        });
      } else if (storyType === 'V3::Customer::Stories::Simple') {
        createSimpleStory({
          variables: {
            customerId: customerId,
            name: name,
            priority: parseInt(priority),
            accountSelectorId: accountSelectorId,
            contactSelectorId: contactSelectorId,
            rulesOfEngagementId: rulesOfEngagementId,
            sendingWindowDayStart: parseInt(sendingWindowDayStart),
            sendingWindowDayEnd: parseInt(sendingWindowDayEnd),
            sendingWindowHourStart: parseInt(sendingWindowHourStart),
            sendingWindowHourEnd: parseInt(sendingWindowHourEnd),
            pausedAt: new Date(),
          },
          refetchQueries: ['v3_Customer_Stories'],
        }).then((data) => {
          tracker.track('Simple Story Created');
          history.push('/stories');
        });
      } else if (storyType === 'V3::Customer::Stories::ClosedLost') {
        createClosedLostStory({
          variables: {
            customerId: customerId,
            name: name,
            priority: parseInt(priority),
            accountSelectorId: accountSelectorId,
            contactSelectorId: contactSelectorId,
            rulesOfEngagementId: rulesOfEngagementId,
            pausedAt: new Date(),
          },
          refetchQueries: ['v3_Customer_Stories'],
        }).then((data) => {
          tracker.track('Closed Lost Story Created');
          history.push('/stories');
        });
      } else if (storyType === 'V3::Customer::Stories::OpenOppsNoActivity') {
        createOpenOppsNoActivityStory({
          variables: {
            customerId: customerId,
            name: name,
            priority: parseInt(priority),
            accountSelectorId: accountSelectorId,
            contactSelectorId: contactSelectorId,
            rulesOfEngagementId: rulesOfEngagementId,
            pausedAt: new Date(),
          },
          refetchQueries: ['v3_Customer_Stories'],
        }).then((data) => {
          tracker.track('Open Opps No Acitivity Story Created');
          history.push('/stories');
        });
      } else if (storyType === 'V3::Customer::Stories::OldCustomerNewJob') {
        createOldCustomerNewJobStory({
          variables: {
            customerId: customerId,
            name: name,
            priority: parseInt(priority),
            accountSelectorId: accountSelectorId,
            contactSelectorId: contactSelectorId,
            rulesOfEngagementId: rulesOfEngagementId,
            sendingWindowDayStart: parseInt(sendingWindowDayStart),
            sendingWindowDayEnd: parseInt(sendingWindowDayEnd),
            sendingWindowHourStart: parseInt(sendingWindowHourStart),
            sendingWindowHourEnd: parseInt(sendingWindowHourEnd),
            pausedAt: new Date(),
          },
          refetchQueries: ['v3_Customer_Stories'],
        }).then((data) => {
          tracker.track('Old Customer New Job Story Created');
          history.push('/stories');
        });
      } else {
        createDemoStory({
          variables: {
            customerId: customerId,
            name: name,
            priority: parseInt(priority),
            accountSelectorId: accountSelectorId,
            contactSelectorId: contactSelectorId,
            rulesOfEngagementId: rulesOfEngagementId,
            sendingWindowDayStart: parseInt(sendingWindowDayStart),
            sendingWindowDayEnd: parseInt(sendingWindowDayEnd),
            sendingWindowHourStart: parseInt(sendingWindowHourStart),
            sendingWindowHourEnd: parseInt(sendingWindowHourEnd),
            pausedAt: new Date(),
          },
          refetchQueries: ['v3_Customer_Stories'],
        }).then((data) => {
          tracker.track('Demo Story Created');
          toggleModal();
        });
      }
    }
  };

  // const options = Titles.map(title => {
  //   return { value: title, label: title };
  // });

  const {
    data: accountSelectorData,
    loading: accountSelectorLoading,
  } = useQuery(ACCOUNTSELECTORS_QUERY, {
    variables: { customerId: customerId },
  });

  const {
    data: contactSelectorData,
    loading: contactSelectorLoading,
  } = useQuery(CONTACTSELECTORS_QUERY, {
    variables: { customerId: customerId },
  });

  const { data: roeData, loading: roeLoading } = useQuery(ROE_QUERY, {
    variables: { customerId: customerId },
  });

  if (accountSelectorLoading || contactSelectorLoading || roeLoading)
    return null;

  const accountSelectorOptions = (
    accountSelectorData.accountSelectors || []
  ).map((x) => {
    return {
      value: x._id,
      label:
        x.name + ' (' + x.totalMatchingAccountsCount + ' Matching Accounts)',
    };
  });

  const contactSelectorOptions = (
    contactSelectorData.contactSelectors || []
  ).map((x) => {
    return {
      value: x._id,
      label:
        x.name + ' (' + x.totalMatchingContactsCount + ' Matching Contacts)',
    };
  });

  const roeOptions = (roeData?.baseRoes || []).map((x) => {
    return {
      value: x._id,
      label: x.name,
    };
  });

  return (
    <React.Fragment>
      {story._id ? (
        <Button
          color="secondary"
          onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }}
        >
          <i className="fa fa-edit mr-2"></i>Edit
        </Button>
      ) : (
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }}
        >
          Create new
        </Button>
      )}

      <Modal isOpen={isOpen} toggle={toggleModal}>
        <Form name="formStory" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggleModal}>
            {story._id ? 'Edit Story' : 'Create Story'}
          </ModalHeader>
          <ModalBody>
            <Container className="container-md">
              <FormGroup>
                <label>Type</label>
                <select
                  className="custom-select custom-select-sm"
                  defaultValue={story.storyType}
                  ref={register({ required: 'Story Type is required' })}
                  name="storyType"
                >
                  <option value="V3::Customer::Stories::Basic">Advanced</option>
                  {/* <option value="V3::Customer::Stories::Simple">Simple</option> */}
                  <option value="V3::Customer::Stories::ClosedLost">
                    Closed Lost Re-engagement
                  </option>
                  <option value="V3::Customer::Stories::OpenOppsNoActivity">
                    Open Opps, No Activity Re-engagement
                  </option>
                  <option value="V3::Customer::Stories::OldCustomerNewJob">
                    Old Customer - New Job
                  </option>
                  <option value="V3::Customer::Stories::Demo">Demo</option>
                </select>
              </FormGroup>
              <FormGroup>
                <label>Name</label>
                <Input
                  type="text"
                  placeholder="Name"
                  name="name"
                  invalid={errors.name}
                  innerRef={register(name)}
                  defaultValue={story.name}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  className="invalid-feedback"
                  as="p"
                ></ErrorMessage>
              </FormGroup>
              <FormGroup>
                <Input
                  type="button"
                  value={
                    showAdvanced
                      ? 'Hide Advanced Settings'
                      : 'Show Advanced Settings'
                  }
                  onClick={() => toggleAdvanced(!showAdvanced)}
                />
              </FormGroup>
              {showAdvanced && (
                <>
                  <FormGroup>
                    <label>Priority</label>
                    <select
                      className="custom-select custom-select-sm"
                      name="priority"
                      ref={register({ required: 'Priority is required' })}
                      defaultValue={story.priority || 1}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="1000">1000 (Story Preview Mode)</option>
                    </select>
                  </FormGroup>
                  {storyType !== 'V3::Customer::Stories::ClosedLost' &&
                    storyType !== 'V3::Customer::Stories::OldCustomerNewJob' &&
                    storyType !==
                      'V3::Customer::Stories::OpenOppsNoActivity' && (
                      <FormGroup>
                        <label>Account Targeting</label>
                        <select
                          name="accountSelectorId"
                          className="custom-select custom-select-sm"
                          invalid={errors.accountSelectorId}
                          ref={register({
                            required: 'Account Targeting is required',
                          })}
                          defaultValue={story.accountSelectorId}
                        >
                          <option value="">Select Account Targeting</option>
                          {accountSelectorOptions
                            .slice()
                            .sort(sortingByProperty('label'))
                            .map((x) => (
                              <option key={x.value} value={x.value}>
                                {x.label}
                              </option>
                            ))}
                        </select>
                        <ErrorMessage
                          errors={errors}
                          name="accountSelectorId"
                          style={{
                            fontSize: '80%',
                            color: '#F45B53',
                            marginTop: '0.25rem',
                          }}
                          as="p"
                        ></ErrorMessage>
                      </FormGroup>
                    )}
                  <FormGroup>
                    <label>Contact Targeting</label>
                    <select
                      className="custom-select custom-select-sm"
                      name="contactSelectorId"
                      invalid={errors.contactSelectorId}
                      ref={register({
                        required: 'Contact Targeting is required',
                      })}
                      defaultValue={story.contactSelectorId}
                    >
                      <option value="">Select Contact Targeting</option>
                      {contactSelectorOptions
                        .slice()
                        .sort(sortingByProperty('label'))
                        .map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.label}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="contactSelectorId"
                      style={{
                        fontSize: '80%',
                        color: '#F45B53',
                        marginTop: '0.25rem',
                      }}
                      as="p"
                    ></ErrorMessage>
                  </FormGroup>
                  <FormGroup>
                    <label>Rules of Engagement</label>
                    <select
                      className="custom-select custom-select-sm"
                      name="rulesOfEngagementId"
                      invalid={errors.rulesOfEngagementId}
                      ref={register({
                        required: 'Rules of Engagement is required',
                      })}
                      defaultValue={story.rulesOfEngagementId}
                    >
                      <option value="">Select Rules of Engagement</option>
                      {roeOptions
                        .slice()
                        .sort(sortingByProperty('label'))
                        .map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.label}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="rulesOfEngagementId"
                      style={{
                        fontSize: '80%',
                        color: '#F45B53',
                        marginTop: '0.25rem',
                      }}
                      as="p"
                    ></ErrorMessage>
                  </FormGroup>
                </>
              )}
              {storyType === 'V3::Customer::Stories::Simple' && !showAdvanced && (
                <>
                  <input
                    type="hidden"
                    name="priority"
                    ref={register({
                      required: 'Priority is required',
                    })}
                    defaultValue={story?.priority || 100}
                  />
                  <input
                    type="hidden"
                    name="accountSelectorId"
                    ref={register({
                      required: 'Account Selector is required',
                    })}
                    defaultValue={
                      story?.accountSelectorId ||
                      accountSelectorOptions[0]?.value
                    }
                  />
                  <input
                    type="hidden"
                    name="contactSelectorId"
                    ref={register({
                      required: 'Contact Selector is required',
                    })}
                    defaultValue={
                      story?.contactSelectorId ||
                      contactSelectorOptions[0]?.value
                    }
                  />
                  <input
                    type="hidden"
                    name="rulesOfEngagementId"
                    invalid={errors.rulesOfEngagementId}
                    ref={register({
                      required: 'Rules of Engagement is required',
                    })}
                    defaultValue={
                      story.rulesOfEngagementId || roeOptions[0]?.value
                    }
                  />
                </>
              )}
              {storyType === 'V3::Customer::Stories::ClosedLost' && (
                <>
                  <FormGroup>
                    <label>Account Targeting</label>
                    {accountSelectorOptions.filter(
                      (x) => x.selectorType === 'OpportunityClosedLost'
                    ).length === 0 && (
                      <>
                        <p className="p-4 bg-warning">
                          You do not have any Closed Lost Account Targeting
                          setup
                        </p>
                        <a
                          className="btn btn-primary mb-2"
                          href={'#/settings/icp'}
                        >
                          Create Closed Lost Account Targeting
                        </a>
                      </>
                    )}
                    <select
                      name="accountSelectorId"
                      className="custom-select custom-select-sm"
                      invalid={errors.accountSelectorId}
                      ref={register({
                        required: 'Account Targeting is required',
                      })}
                      defaultValue={story.accountSelectorId}
                    >
                      <option value="">Select Account Targeting</option>
                      {accountSelectorOptions
                        .filter(
                          (x) => x.selectorType === 'OpportunityClosedLost'
                        )
                        .slice()
                        .sort(sortingByProperty('label'))
                        .map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.label}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="accountSelectorId"
                      style={{
                        fontSize: '80%',
                        color: '#F45B53',
                        marginTop: '0.25rem',
                      }}
                      as="p"
                    ></ErrorMessage>
                  </FormGroup>
                  {!showAdvanced && (
                    <>
                      <input
                        type="hidden"
                        name="priority"
                        ref={register({
                          required: 'Priority is required',
                        })}
                        defaultValue={story.priority || 100}
                      />
                      <input
                        type="hidden"
                        name="contactSelectorId"
                        ref={register({
                          required: 'Contact Selector is required',
                        })}
                        defaultValue={
                          story.contactSelectorId ||
                          contactSelectorOptions[0]?.value
                        }
                      />
                      <input
                        type="hidden"
                        name="rulesOfEngagementId"
                        invalid={errors.rulesOfEngagementId}
                        ref={register({
                          required: 'Rules of Engagement is required',
                        })}
                        defaultValue={
                          story.rulesOfEngagementId || roeOptions[0]?.value
                        }
                      />
                    </>
                  )}
                </>
              )}
              {storyType === 'V3::Customer::Stories::OpenOppsNoActivity' && (
                <>
                  <FormGroup>
                    <label>Account Targeting</label>
                    {accountSelectorOptions.filter(
                      (x) => x.selectorType === 'OpenOpportunity'
                    ).length === 0 && (
                      <>
                        <p className="p-4 bg-warning">
                          You do not have any Open Opportunity Account Targeting
                          setup
                        </p>
                        <a
                          className="btn btn-primary mb-2"
                          href={'#/settings/icp'}
                        >
                          Create Open Opportunity Account Targeting
                        </a>
                      </>
                    )}
                    <select
                      name="accountSelectorId"
                      className="custom-select custom-select-sm"
                      invalid={errors.accountSelectorId}
                      ref={register({
                        required: 'Account Targeting is required',
                      })}
                      defaultValue={story.accountSelectorId}
                    >
                      <option value="">Select Account Targeting</option>
                      {accountSelectorOptions
                        .filter((x) => x.selectorType === 'OpenOpportunity')
                        .slice()
                        .sort(sortingByProperty('label'))
                        .map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.label}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="accountSelectorId"
                      style={{
                        fontSize: '80%',
                        color: '#F45B53',
                        marginTop: '0.25rem',
                      }}
                      as="p"
                    ></ErrorMessage>
                  </FormGroup>
                  {!showAdvanced && (
                    <>
                      <input
                        type="hidden"
                        name="priority"
                        ref={register({
                          required: 'Priority is required',
                        })}
                        defaultValue={story.priority || 100}
                      />
                      <input
                        type="hidden"
                        name="contactSelectorId"
                        ref={register({
                          required: 'Contact Selector is required',
                        })}
                        defaultValue={
                          story.contactSelectorId ||
                          contactSelectorOptions[0]?.value
                        }
                      />
                      <input
                        type="hidden"
                        name="rulesOfEngagementId"
                        invalid={errors.rulesOfEngagementId}
                        ref={register({
                          required: 'Rules of Engagement is required',
                        })}
                        defaultValue={
                          story.rulesOfEngagementId || roeOptions[0]?.value
                        }
                      />
                    </>
                  )}
                </>
              )}
              {storyType === 'V3::Customer::Stories::OldCustomerNewJob' && (
                <>
                  <FormGroup>
                    <label>Account Targeting</label>
                    {accountSelectorOptions.filter(
                      (x) => x.selectorType === 'CustomerList'
                    ).length === 0 && (
                      <>
                        <p className="p-4 bg-warning">
                          You do not have any Customer List Account Targeting
                          setup
                        </p>
                        <a
                          className="btn btn-primary mb-2"
                          href={'#/settings/icp'}
                        >
                          Create Customer List Account Targeting
                        </a>
                      </>
                    )}
                    <select
                      name="accountSelectorId"
                      className="custom-select custom-select-sm"
                      invalid={errors.accountSelectorId}
                      ref={register({
                        required: 'Account Targeting is required',
                      })}
                      defaultValue={story.accountSelectorId}
                    >
                      <option value="">Select Account Targeting</option>
                      {accountSelectorOptions
                        .filter((x) => x.selectorType === 'CustomerList')
                        .slice()
                        .sort(sortingByProperty('label'))
                        .map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.label}
                          </option>
                        ))}
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="accountSelectorId"
                      style={{
                        fontSize: '80%',
                        color: '#F45B53',
                        marginTop: '0.25rem',
                      }}
                      as="p"
                    ></ErrorMessage>
                  </FormGroup>
                  {!showAdvanced && (
                    <>
                      <input
                        type="hidden"
                        name="priority"
                        ref={register({
                          required: 'Priority is required',
                        })}
                        defaultValue={story.priority || 100}
                      />
                      <input
                        type="hidden"
                        name="contactSelectorId"
                        ref={register({
                          required: 'Contact Selector is required',
                        })}
                        defaultValue={
                          story.contactSelectorId ||
                          contactSelectorOptions[0]?.value
                        }
                      />
                      <input
                        type="hidden"
                        name="rulesOfEngagementId"
                        invalid={errors.rulesOfEngagementId}
                        ref={register({
                          required: 'Rules of Engagement is required',
                        })}
                        defaultValue={
                          story?.rulesOfEngagementId || roeOptions[0]?.value
                        }
                      />
                    </>
                  )}
                </>
              )}
              <FormGroup>
                <Row>
                  <Col>
                    <label>Sending Window Start Day</label>
                    <select
                      className="custom-select custom-select-sm"
                      name="sendingWindowDayStart"
                      ref={register({ required: 'Sending window is required' })}
                      defaultValue={
                        story?.sendingWindowDayStart ||
                        story?.sendingWindowDayStart === 0 ||
                        1
                      }
                    >
                      <option value="1">Monday</option>
                      <option value="2">Tuesday</option>
                      <option value="3">Wednesday</option>
                      <option value="4">Thursday</option>
                      <option value="5">Friday</option>
                      <option value="6">Saturday</option>
                      <option value="0">Sunday</option>
                    </select>
                  </Col>
                  <Col>
                    <label>Sending Window End Day</label>
                    <select
                      className="custom-select custom-select-sm"
                      name="sendingWindowDayEnd"
                      ref={register({ required: 'Sending window is required' })}
                      defaultValue={
                        story?.sendingWindowDayEnd ||
                        story?.sendingWindowDayEnd === 0 ||
                        6
                      }
                    >
                      <option value="1">Monday</option>
                      <option value="2">Tuesday</option>
                      <option value="3">Wednesday</option>
                      <option value="4">Thursday</option>
                      <option value="5">Friday</option>
                      <option value="6">Saturday</option>
                      <option value="0">Sunday</option>
                    </select>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <label>Sending Window Start Hour</label>
                    <select
                      className="custom-select custom-select-sm"
                      name="sendingWindowHourStart"
                      ref={register({ required: 'Sending window is required' })}
                      defaultValue={
                        story?.sendingWindowHourStart ||
                        story?.sendingWindowHourStart === 0 ||
                        5
                      }
                    >
                      <option value="0">12 am</option>
                      <option value="1">1 am</option>
                      <option value="2">2 am</option>
                      <option value="3">3 am</option>
                      <option value="4">4 am</option>
                      <option value="5">5 am</option>
                      <option value="6">6 am</option>
                      <option value="7">7 am</option>
                      <option value="8">8 am</option>
                      <option value="9">9 am</option>
                      <option value="10">10 am</option>
                      <option value="11">11 am</option>
                      <option value="12">12 pm</option>
                      <option value="13">1 pm</option>
                      <option value="14">2 pm</option>
                      <option value="15">3 pm</option>
                      <option value="16">4 pm</option>
                      <option value="17">5 pm</option>
                      <option value="18">6 pm</option>
                      <option value="19">7 pm</option>
                      <option value="20">8 pm</option>
                      <option value="21">9 pm</option>
                      <option value="22">10 pm</option>
                      <option value="23">11 pm</option>
                    </select>
                  </Col>
                  <Col>
                    <label>Sending Window End Hour</label>
                    <select
                      className="custom-select custom-select-sm"
                      name="sendingWindowHourEnd"
                      ref={register({ required: 'Sending window is required' })}
                      defaultValue={
                        story?.sendingWindowHourEnd ||
                        story?.sendingWindowHourEnd === 0 ||
                        10
                      }
                    >
                      <option value="0">12 am</option>
                      <option value="1">1 am</option>
                      <option value="2">2 am</option>
                      <option value="3">3 am</option>
                      <option value="4">4 am</option>
                      <option value="5">5 am</option>
                      <option value="6">6 am</option>
                      <option value="7">7 am</option>
                      <option value="8">8 am</option>
                      <option value="9">9 am</option>
                      <option value="10">10 am</option>
                      <option value="11">11 am</option>
                      <option value="12">12 pm</option>
                      <option value="13">1 pm</option>
                      <option value="14">2 pm</option>
                      <option value="15">3 pm</option>
                      <option value="16">4 pm</option>
                      <option value="17">5 pm</option>
                      <option value="18">6 pm</option>
                      <option value="19">7 pm</option>
                      <option value="20">8 pm</option>
                      <option value="21">9 pm</option>
                      <option value="22">10 pm</option>
                      <option value="23">11 pm</option>
                    </select>
                  </Col>
                </Row>
              </FormGroup>
            </Container>
          </ModalBody>
          <ModalFooter>
            <SpinnerButton
              type="submit"
              color={story._id ? 'secondary' : 'primary'}
              loading={
                createDemoLoading ||
                createSimpleStoryLoading ||
                createOldCustomerNewJobStoryLoading ||
                createClosedLostStoryLoading ||
                createOpenOppsNoActivityStoryLoading ||
                createStoryLoading ||
                updateStoryLoading
              }
            >
              {story._id ? 'Save' : 'Create'}
            </SpinnerButton>{' '}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(StoryEditor);
