import React, { useState, useEffect } from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Button,
} from 'reactstrap';
import { SENDERS_LIST_QUERY } from '@koncert/graphql';
import { useQuery } from '@apollo/client';
import { sortingByProperty } from '../Utils/index';

export const useSendersList = (
  currentUser,
  userLoading,
  defaultSenderId,
  disableAll = false,
  disableNonDefault = false,
  disableMe = false,
  disableAllSenders = false
) => {
  const [senderId, setSenderId] = useState(defaultSenderId);
  const [reportUser, setReportUser] = useState('All Senders');
  const [reportUserOpen, setReportUserOpen] = useState(false);
  const [groupBySender, setGroupBySender] = useState(false);
  const { data: senders, loading, error } = useQuery(SENDERS_LIST_QUERY, {
    skip: userLoading,
  });

  const SendersDropdown = () => {
    useEffect(() => {
      if (senders && senderId !== null) {
        if (senderId === currentUser?._id) {
          setReportUser(currentUser?.fullName);
        } else {
          if (senders.users.length > 0) {
            const x = senders?.users?.filter((s) => s._id === senderId);
            if (x.length > 0) setReportUser(x[0].fullName);
          }
        }
      }
    }, []);
    if (userLoading || loading)
      return (
        <Button>
          <i className="fa fa-spinner fa-spin"></i>
        </Button>
      );

    return (
      <ButtonDropdown
        isOpen={reportUserOpen}
        toggle={() => setReportUserOpen(!reportUserOpen)}
        id="report_user"
        className="mr-2"
      >
        <DropdownToggle caret color="secondary">
          <i className="fa fa-user mr-2 text-primary"></i>
          {reportUser}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            active={senderId === null}
            disabled={disableAll || disableAllSenders}
            onClick={() => {
              setReportUser('All Senders');
              setGroupBySender(false);
              setSenderId(null);
            }}
          >
            All Senders
          </DropdownItem>
          <DropdownItem
            active={senderId === currentUser?._id}
            disabled={disableMe}
            onClick={() => {
              setReportUser(currentUser?.fullName);
              setGroupBySender(true);
              setSenderId(currentUser?._id);
            }}
          >
            {currentUser?.fullName}
          </DropdownItem>
          {senders &&
            senders.users
              ?.slice()
              ?.sort(sortingByProperty('fullName'))
              ?.map((sender) => {
                if (sender._id !== currentUser?._id)
                  return (
                    <DropdownItem
                      active={senderId === sender?._id}
                      key={sender?._id}
                      disabled={
                        disableAll ||
                        (disableNonDefault && defaultSenderId !== sender?._id)
                      }
                      onClick={() => {
                        setReportUser(sender.fullName);
                        setGroupBySender(true);
                        setSenderId(sender?._id);
                      }}
                    >
                      {sender.fullName}
                    </DropdownItem>
                  );
                return null;
              })}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  return {
    SendersDropdown,
    senderId,
    senders,
    loading,
    error,
    reportUser,
    groupBySender,
    setSenderId,
    setReportUser,
    setGroupBySender,
  };
};
