import gql from 'graphql-tag';

export const STORIESCONTACTSTATISTICSQUERY = gql`
query($storyId: ID!) {
  story(where : {_id: $storyId}) {
      _id
      eligibleContactAnalysis
    }
  }
`;
