import gql from 'graphql-tag';
// import React from 'react';
// import { graphql } from '@apollo/react-hoc';

// export const AccountDatasQuery = (ComponentToWrap) => {
//   const WrappedComponent = (props) => {
//     const { data, loading, ...restProps } = props;
//     return (
//       <ComponentToWrap
//         loading={!!loading || data.loading}
//         accounts={data.v3_Customer_AccountDatas || []}
//         {...restProps}
//       />
//     );
//   };
//   return graphql(ACCOUNTDATASQUERYSTRING)(WrappedComponent);
// };

export const ACCOUNTDATASQUERYSTRING = gql`
  query accountData {
    accountDatas(limit: 10) {
       _id
      name{
        value
      }
      domains{
        value
      }
      logo{
        value
      }
      industries{
        value
      }
      employeeCountRanges{
        value
      }
      status
    }
  }
`;
