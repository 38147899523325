import gql from 'graphql-tag';

export const STORY_CONTACT_STATUS = gql`
  mutation SetStoryContactStatus(
    $id: ID!
    $status: String!
    $approved: Boolean = false
  ) {
    updatestoryContact(
      id: $id
      data: { status: $status, approved: $approved }
    ) {
      _id
    }
  }
`;

export default STORY_CONTACT_STATUS;
