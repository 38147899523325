import gql from 'graphql-tag';

export const STORYJOURNALAGGREGATION = gql`
  query identifiedContacts(
    $format: String = "day"
    $event: String = "contact_identified"
    $startDate: DateTime
    $endDate: DateTime
    $storyId: ID
    $groupByStoryId: Boolean = false
    $senderId: ID
    $groupBySender: Boolean = false
    $groupByAccount: Boolean = false
    $accountId: ID
  ) {
    storyJournalAggregation(
      format: $format
      event: $event
      startDate: $startDate
      endDate: $endDate
      storyId: $storyId
      groupByStory: $groupByStoryId
      senderId: $senderId
      groupBySender: $groupBySender
      groupByAccountId: $groupByAccount
      accountId: $accountId
    ) {
      id
      event
      startDate
      endDate
      data
      totalCount
      totalAccountCount
    }
  }
`;

export default STORYJOURNALAGGREGATION;
