import React from 'react';

type data = {
  requestId?: string;
};

type response = {
  graphQLErrors: message[];
  networkError: message;
};

type message = {
  message: string;
};

type ErrorHandlerProps = {
  response: response;
  data: data;
};

const TableRowError = (props: ErrorHandlerProps) => {
  const { response, data } = props;
  const errorMessage = response?.graphQLErrors?.[0]?.message
    ? response.graphQLErrors[0].message
    : response?.networkError?.message;
  return (
    <tr>
      <td colSpan={12} className="text-center mb-0 bg-gray-lighter">
        <span className="text-danger">
          <i className="fas fa-exclamation-circle mr-2"></i>
          {errorMessage ? errorMessage : 'Failed to fetch data'}
        </span>
        {data?.requestId && (
          <div className="text-danger text-sm mt-2">{`Request Id: ${data?.requestId}`}</div>
        )}
      </td>
    </tr>
  );
};

export default TableRowError;
