import React, { useState, useMemo, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { LinearGradientFill } from '@koncert/shared-components';
import { ACCOUNTJOURNALAGGREGATION } from '@koncert/graphql';
import { useQuery } from '@apollo/client';
import { UserContext } from '@koncert/shared-components';
import * as moment from 'moment';

export const AccountJournalGraph = ({
  customerId,
  title,
  color,
  bgColorClass,
  startColor,
  endColor,
  event,
  format,
  startDate,
  endDate,
  accountId,
  groupByAccountId,
  senderId,
  groupBySender,
  period,
}) => {
  const { user, loading: userLoading } = useContext(UserContext);

  const [fixedData, setFixedData] = useState([]);
  const [totalData, setTotalData] = useState(0);

  const { loading, data } = useQuery(ACCOUNTJOURNALAGGREGATION, {
    variables: {
      customerId: customerId || user?.companyId,
      format: format,
      event: event,
      startDate: startDate,
      endDate: endDate,
      accountId: accountId,
      groupByAccountId: groupByAccountId,
      senderId: senderId,
      groupBySender: groupBySender,
    },
    skip: userLoading,
  });

  useMemo(() => {
    if (data && data.accountJournalAggregation) {
      const dataArray = data.accountJournalAggregation.data.map((x) => x.count);
      setFixedData(dataArray.concat(Array(5).fill(1)).slice(0, 5));
      setTotalData(data.accountJournalAggregation.totalCount);
    }
  }, [data]);
  if (userLoading) return null;
  return (
    <div className={'card ' + bgColorClass + ' pt-2 b'}>
      <div className="px-2 flex-grow-2">
        <div className="h2 mt0">
          {loading && <i className="fa fa-spinner fa-spin"></i>}
          {!loading && <span>{totalData}</span>}
        </div>
        <div>{title}</div>
      </div>
      {loading && '...'}
      {!loading && fixedData.length > 0 && (
        <div className="p-2 sparklink-graph">
          <Sparklines data={fixedData} margin={-2}>
            <svg>
              <defs>
                <LinearGradientFill
                  startColor={startColor || color}
                  endColor={endColor || color}
                  id={event}
                />
              </defs>
            </svg>
            <SparklinesLine
              color={color}
              style={{
                strokeWidth: 1,
                fill: '#83CFFF',
              }}
            />
          </Sparklines>
        </div>
      )}
      {!loading && fixedData.length === 0 && (
        <div>
          <Sparklines data={[0, 0, 0, 0]} margin={-2}>
            <svg>
              <defs>
                <LinearGradientFill
                  startColor={startColor || color}
                  endColor={endColor || color}
                  id={event}
                />
              </defs>
            </svg>
            <SparklinesLine
              color={color}
              style={{
                strokeWidth: 1,
                fill: '#83CFFF',
              }}
            />
          </Sparklines>
        </div>
      )}
      <div className="card-footer">
        <p>
          <em className="fa fa-chart-bar fa-fw mr-1"></em>
          <span className="mr-1">{period}</span>
          <span className="d-inline-block">
            ({moment(startDate).format('MM/DD/YYYY')} -{' '}
            {moment(endDate).format('MM/DD/YYYY')})
          </span>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerId: state.customer.id,
  customerName: state.customer.name,
});
const connectedAccountJournalGraph = connect(mapStateToProps)(
  AccountJournalGraph
);

export default withTranslation('translations')(connectedAccountJournalGraph);
