import gql from 'graphql-tag';

export const UPDATE_CONTACT_SELECTOR = gql`
  mutation UpdateContactSelector(
    $contactSelectorArgs: ContactSelectorUpdateDTO!
    $selectorFilterCriteriaArgs: [SelectorFilterCriteriaUpdateDTO!]
  ) {
    updateContactSelector(
      contactSelectorArgs: $contactSelectorArgs
      selectorFilterCriteriaArgs: $selectorFilterCriteriaArgs
    )
  }
`;

export default UPDATE_CONTACT_SELECTOR;
