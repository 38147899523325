/**
 * @author @Panimaya-Albert
 * @version V11.2
 */
import { gql } from '@apollo/client';

const FETCH_EMAILS_QUERY = gql`
  query(
    $includeAssociationsQry: String!
    $limit: String!
    $offset: String!
    $emailsFilter: String!
  ) {
    mailings(
      includeAssociationsQry: $includeAssociationsQry
      limit: $limit
      offset: $offset
      emailsFilter: $emailsFilter
    )
      @rest(
        type: "Mailings"
        path: "mailings/list?{args.includeAssociationsQry}&page[limit]={args.limit}&page[offset]={args.offset}{args.emailsFilter}"
      ) {
      data
      includedAssociations
      paging
    }
  }
`;

export const FETCH_EMAILS_COUNT_QUERY = gql`
  query($userFilter: String!, $dateFilter: String!) {
    mailings(userFilter: $userFilter, dateFilter: $dateFilter)
      @rest(
        type: "Mailings"
        path: "mailings/count?{args.userFilter}{args.dateFilter}"
      ) {
      data
      response
    }
  }
`;

export const FETCH_USER_REPLY_TO_PROSPECT = gql`
  query($emailFilter: String!) {
    mailings(emailFilter: $emailFilter)
      @rest(
        type: "Mailings"
        path: "mailings/userReplyToProspect?{args.emailFilter}"
      ) {
      response
      data
    }
  }
`;

export const FETCH_EMAIL_QUERY = gql`
  query(
    $emailId: ID!
    $emailsFilter: String!
    $includeAssociationsQry: String!
  ) {
    mailing(
      emailId: $emailId
      emailsFilter: $emailsFilter
      includeAssociationsQry: $includeAssociationsQry
    )
      @rest(
        type: "Mailings"
        path: "mailings/view/{args.emailId}?{args.emailsFilter}&{args.includeAssociationsQry}"
      ) {
      data
      includedAssociations
      paging
    }
  }
`;

export const FETCH_PROSPECTS_DATA_QUERY = gql`
  query($userFilter: String!, $limit: String!, $offset: String!) {
    prospects(userFilter: $userFilter, limit: $limit, offset: $offset)
      @rest(
        type: "Prospects"
        path: "mailings/prospects?{args.userFilter}&page[limit]={args.limit}&page[offset]={args.offset}"
      ) {
      response
      data
      paging
    }
  }
`;

export const CANCEL_EMAIL_QUERY = gql`
  query($emailId: ID!, $userFilter: String!) {
    mailing(emailId: $emailId, userFilter: $userFilter)
      @rest(
        type: "Mailing"
        path: "mailings/{args.emailId}/cancel?{args.userFilter}"
        method: "DELETE"
      ) {
      response
      data
    }
  }
`;

export const RESCHEDULE_EMAIL_QUERY = gql`
  query($emailId: ID!, $userFilter: String!, $input: Object!) {
    mailing(emailId: $emailId, userFilter: $userFilter, input: $input)
      @rest(
        type: "Mailing"
        path: "mailings/{args.emailId}/reschedule?{args.userFilter}"
        method: "PUT"
      ) {
      response
      data
    }
  }
`;

export default FETCH_EMAILS_QUERY;
