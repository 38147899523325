import gql from 'graphql-tag';

export const CREATE_BASIC_STORY = gql`
  mutation CreateBasicStory(
    $name: String!
    $priority: Int!
    $accountSelectorId: ID!
    $contactSelectorId: ID!
    $rulesOfEngagementId: ID!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int!
    $sendingWindowDayEnd: Int!
    $sendingWindowHourStart: Int!
    $sendingWindowHourEnd: Int!
  ) {
    createstory(
      data: {
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      _id
    }
  }
`;

export const CREATE_SIMPLE_STORY = gql`
  mutation CreateSimpleStory(
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createsimpleStory(
      data: {
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      _id
    }
  }
`;

export const CREATE_CLOSED_LOST_STORY = gql`
  mutation CreateClosedLostStory(
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_ClosedLost(
      data: {
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;

export const CREATE_OPEN_OPPS_NO_ACTIVITY_STORY = gql`
  mutation CreateOpenOppsNoActivityStory(
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_OpenOppsNoActivity(
      data: {
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;

export const CREATE_OLD_CUSTOMER_NEW_JOB_STORY = gql`
  mutation CreateOldCustomerNewJobStory(
    $name: String!
    $priority: Int
    $accountSelectorId: String!
    $contactSelectorId: String!
    $rulesOfEngagementId: String!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int
    $sendingWindowDayEnd: Int
    $sendingWindowHourStart: Int
    $sendingWindowHourEnd: Int
  ) {
    createV3_Customer_Stories_OldCustomerNewJob(
      data: {
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      id
    }
  }
`;

export const CREATE_DEMO_STORY = gql`
  mutation CreateDemoStory(
    $name: String!
    $priority: Int!
    $accountSelectorId: ID!
    $contactSelectorId: ID!
    $rulesOfEngagementId: ID!
    $pausedAt: DateTime
    $sendingWindowDayStart: Int!
    $sendingWindowDayEnd: Int!
    $sendingWindowHourStart: Int!
    $sendingWindowHourEnd: Int!
  ) {
    createdemoStory(
      data: {
        name: $name
        priority: $priority
        accountSelectorId: $accountSelectorId
        contactSelectorId: $contactSelectorId
        rulesOfEngagementId: $rulesOfEngagementId
        pausedAt: $pausedAt
        sendingWindowDayStart: $sendingWindowDayStart
        sendingWindowDayEnd: $sendingWindowDayEnd
        sendingWindowHourStart: $sendingWindowHourStart
        sendingWindowHourEnd: $sendingWindowHourEnd
      }
    ) {
      _id
    }
  }
`;
