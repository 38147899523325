import gql from 'graphql-tag';

export const CREATE_PLOTPOINT = gql`
  mutation CreatePlotPoint(
    $storyId: ID!
    $messageId: ID!
    $name: String!
    $position: Int
    $plotPointCategoryId: ID!
  ) {
    createplotPoint(
      data: {
        name: $name
        storyId: $storyId
        messageId: $messageId
        position: $position
        plotPointCategoryId: $plotPointCategoryId
      }
    ) {
      _id
    }
  }
`;
