import gql from 'graphql-tag';

export const INTEGRATION_QUERY = gql`
  query v3_Customer_Integration($id: ID!) {
    integrations(where: { _id: $id }) {
      _id
      customerId
      createdAt
      updatedAt
      name
      category
      provider
      sections
      protectedIdentities {
        _id
        createdAt
        updatedAt
        nickname
        email
        instanceUrl
        user {
          _id
        }
      }
      syncOnlyUsedAccounts
      syncOnlyUsedContacts
      userSequenceMapping
      selectedAccountFields
      selectedContactFields
      selectedOpportunityFields
      selectedLeadFields
      initialSetupCompleted
      dncReportId
      defaultSender {
        _id
        fullName
        imageUrl
      }
      requiredContactFields
      requiredAccountFields
      requiredOpportunityFields
      importProgress
    }
    connectedUsers(integrationId: $id) {
      nickname
      email
      instanceUrl
      userId
      storySequenceId
      createdAt
    }
  }
`;

export default INTEGRATION_QUERY;
