/**
 * @author @vikrant-gembrill
 * @version V11.2
 * @description Custom hook to fetch notifications count. When multiple tabs are open,every 2 min,
 * in each tab the fetch notification query will first look for the data in the localStorage, and
 * will also check if the data is not older than 2 mins. if not found, or the data is older than 2 mins,
 * will send the api request, else will take the value from the localStorage.
 * In this way, the total no of api queries are reduced if multiple tabs are open.
 */

import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FETCH_NOTIFICATIONS_COUNT } from '../components/queries/NotificationsQuery';

function useNotificationCount(user) {
  const [unReadNotifyCount, setUnReadNotifyCount] = useState(-10);

  const [fetchNotificationsCount] = useLazyQuery(FETCH_NOTIFICATIONS_COUNT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (response) => {
      const queryCompletedTime = new Date().getTime();
      localStorage.setItem(
        'notificationTime',
        JSON.stringify({
          localStorageTime: queryCompletedTime,
          notificationCount: response?.total?.paging?.totalCount,
        })
      );
      setUnReadNotifyCount(response?.total?.paging?.totalCount);
    },
    onError: (error) => {
      if (error.graphQLErrors.length > 0 || error.networkError !== null) {
        setUnReadNotifyCount(-1);
      } else {
        setUnReadNotifyCount(0);
      }
    },
  });

  // first check if the count is available in local storage and if the count is not older than 2 minutes, if yes then
  // use local storage count else send api fetch request
  const fetchNotificationsCountFromHook = () => {
    const localStorageObject = JSON.parse(
      localStorage.getItem('notificationTime')
    );
    const localStorageTime = localStorageObject?.localStorageTime;
    if (localStorageTime) {
      const shouldSkip =
        !user ||
        user?.isFirstTimeUser ||
        (localStorageTime &&
          parseInt(new Date().getTime()) < parseInt(localStorageTime) + 120000);

      if (fetchNotificationsCount && !shouldSkip) {
        fetchNotificationsCount();
      } else {
        const localStorageObject = JSON.parse(
          localStorage.getItem('notificationTime')
        );
        const notificationCount = localStorageObject?.notificationCount;
        if (notificationCount) {
          setUnReadNotifyCount(notificationCount);
        }
      }
    } else if (fetchNotificationsCount) {
      fetchNotificationsCount();
    }
  };

  // fetching the notification count when the component mounts initially.
  useEffect(() => {
    fetchNotificationsCountFromHook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    unReadNotifyCount,
    fetchNotificationsCountFromHook,
    fetchNotificationsCount,
  };
}
export default useNotificationCount;
