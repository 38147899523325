/**
 * @author @rkrishna-gembrill
 * @since Feb 16 2021
 * @version V11.0
 */
import React from 'react';

export const ApiUrlAndTokenContext = React.createContext({});

const ApiUrlAndTokenProvider = ({
  apiURL,
  token,
  jcaValue,
  setJcaValue,
  ...props
}) => {
  return (
    <ApiUrlAndTokenContext.Provider
      value={{ apiURL, token, jcaValue, setJcaValue }}
    >
      {props.children}
    </ApiUrlAndTokenContext.Provider>
  );
};

export default ApiUrlAndTokenProvider;
