// @ts-nocheck
//TODO above should be removed and address the TypeScript errorsimport { InMemoryCache, ApolloClient, ApolloLink } from '@apollo/client';
import React from 'react';
import { ApolloClient } from '@apollo/client';

type LicenseClientCtx = {
  client: ApolloClient;
};

const LicenseClientContext = React.createContext<LicenseClientCtx | null>(null);

export const LicenseClientConsumer = LicenseClientContext.Consumer;

export default LicenseClientContext;
