import gql from 'graphql-tag';

export const DELETE_ACCOUNT_SELECTOR = gql`
  mutation DeleteAccountSelector($id: ID!) {
    deleteaccountSelector(id: $id)
  }
`;

export const DELETE_FILTER_CRITERIA = gql`
  mutation deleteSelectorFilterCriteria($id: ID!) {
    deleteSelectorFilterCriteria(id: $id)
  }
`;

export default DELETE_ACCOUNT_SELECTOR;
