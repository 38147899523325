import gql from 'graphql-tag';

export const ACCOUNTS_QUERY = gql`
  query v3_customer_accounts($filter: JSON, $limit: Int, $skip: Int) {
    accounts(limit: $limit, skip: $skip, filter: $filter) {
      _id
      nameValue
      domainsValue
      salesforceIdsValue
      logoValue
      industriesValue
      salesforceLastActivityDateValue
      employeeCountRangesValue
      lastContactedAt
      owner {
        fullName
      }
      status
    }
    accountsMeta(filter: $filter) {
      count
    }
  }
`;
