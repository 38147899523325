import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import ApolloLinkTimeout from 'apollo-link-timeout';

export const createApolloClient = () => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, error }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        console.log(error);
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // setup `timeoutLink`
  const timeoutLink = new ApolloLinkTimeout(20000); // 20 second timeout
  // setup your `RestLink` with your endpoint
  const restLink = new RestLink({
    uri: process.env.REACT_APP_LICENSE_SERVICE_URL,
  });

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them

    return {
      headers: {
        ...headers,
      },
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, timeoutLink, restLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
  return client;
};

export default createApolloClient;
