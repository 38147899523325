import gql from 'graphql-tag';

export const JOBS_COUNT = gql`
  query jobsCount {
    jobsMeta {
      count
    }
  }
`;

export const CUSTOMER_JOBS_QUERY = gql`
  query jobs {
    jobs(order: { startTime: DESC }, limit: 25) {
      _id
      status
      className
      startTime
      endTime
      parentJobId
      childJobs {
        count
      }
      eventLogs {
        count
      }
    }
  }
`;

export const CUSTOMER_FAILED_JOBS_QUERY = gql`
  query jobs {
    jobs(where: { status: "error" }, order: { startTime: DESC }, limit: 25) {
      _id
      status
      className
      startTime
      endTime
      parentJobId
      childJobs {
        count
      }
      eventLogs {
        count
      }
    }
  }
`;
