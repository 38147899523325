import React from 'react';
import { KoncertLogodiv } from '@koncert/shared-components';
import { useHistory } from 'react-router-dom';

const KoncertSupport = () => {
  return (
    <a
      href="mailto:support@koncert.com"
      style={{
        fontSize: 'inherit',
      }}
    >
      Koncert Support
    </a>
  );
};

const NoUserFound = () => {
  const year = new Date().getFullYear();
  const history = useHistory();
  const pathname = history?.location?.pathname;
  return (
    <div className="pb-5">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ marginTop: '80px' }}
      >
        <h3 className="text-center pl-3">
          <KoncertLogodiv />
        </h3>
      </div>
      <div className="mt-3 d-flex align-items-center justify-content-center">
        <div>
          {pathname === '/noCrmAccountFound' ? (
            <>
              <h6 className="text-danger my-4">
                <i className="fa fa-exclamation-circle mr-2"></i>
                No Koncert user account found matching with your email id.
                Please do the following:
              </h6>
              <ul className="pl-3 mb-4">
                <li className="mb-1">
                  If you are a user, please ask your Admin to invite you to join
                  Koncert. Then, use that invitation to complete your sign up
                  process.
                </li>
                <li>
                  If you are an admin, please complete the sign up process or
                  contact <KoncertSupport />.
                </li>
              </ul>
            </>
          ) : pathname === '/unableToLink' ? (
            <h6 className="text-danger my-4">
              <i className="fa fa-exclamation-circle mr-2"></i>
              <span>
                Unable to link your CRM login with Koncert. Please contact{' '}
                <KoncertSupport />.
              </span>
            </h6>
          ) : pathname === '/crmOrgIdMismatch' ? (
            <h6 className="text-danger my-4">
              <div>
                <i className="fa fa-exclamation-circle mr-2"></i>
                Unable to link your CRM login with Koncert due to CRM Org ID
                configured in Koncert is mismatched with your CRM login.
              </div>
              <div className="mt-2 ml-4">
                Please validate CRM Org ID configured in Koncert{' '}
                <b>Account Management</b> or else please contact{' '}
                <KoncertSupport />.
              </div>
            </h6>
          ) : pathname === '/failedToUpdateCrmUserId' ? (
            <h6 className="text-danger my-4">
              <div>
                <i className="fa fa-exclamation-circle mr-2"></i>
                Unable to link your CRM login with Koncert at this moment.
              </div>
              <div className="mt-2 ml-4">
                Please contact <KoncertSupport />.
              </div>
            </h6>
          ) : pathname === '/unableToCreateUser' ? (
            <h6 className="text-danger my-4">
              <div>
                <i className="fa fa-exclamation-circle mr-2"></i>
                Unable to sign you up at this moment.
              </div>
              <div className="mt-2 ml-4">
                Please contact <KoncertSupport />.
              </div>
            </h6>
          ) : pathname === '/unableToCreateAuth0User' ? (
            <h6 className="text-danger my-4">
              <div>
                <i className="fa fa-exclamation-circle mr-2"></i>
                Unable to sign you up at this moment due to error in IDP.
              </div>
              <div className="mt-2 ml-4">
                Please contact <KoncertSupport />.
              </div>
            </h6>
          ) : pathname === '/signupsDisabled' ? (
            <h6 className="text-danger my-4">
              <div>
                <i className="fa fa-exclamation-circle mr-2"></i>
                Signups are disabled. Please contact your admin to invite you.
              </div>
            </h6>
          ) : (
            <h6 className="text-danger my-4">
              <i className="fa fa-exclamation-circle mr-2"></i>
              <span>
                Unable to link your CRM login with Koncert. Please contact
                <KoncertSupport />.
              </span>
            </h6>
          )}
          <h2 className="text-center">
            <a className="text-decoration-none" href="./">
              <i className="fas fa-sign-in-alt mr-2"></i>Login
            </a>{' '}
            <small>using another account</small>
          </h2>
        </div>
      </div>
      <div className="fixed-bottom py-3 text-center border-top">
        &copy; {year} - Koncert
      </div>
    </div>
  );
};

export default NoUserFound;
