import gql from 'graphql-tag';

export const SENDERS_QUERY = gql`
  query senders($limit: Int = 100, $skip: Int = 0) {
    syncUsers
    users(filter: { rolesMask_lte: 4 }, limit: $limit, skip: $skip) {
      _id
      firstName
      lastName
      fullName
      userType
      rolesMask
      roles
      email
      customerId
      confirmedAt
      sfdcIdentity
      outreachIdentity
      salesloftIdentity
      koncertCadenceIdentity
      mixmaxIdentity
      liFetchQuota
      dailySendingLimit
    }
  }
`;

export const SENDERS_LIST_QUERY = gql`
  query sendersList($limit: Int = 100, $skip: Int = 0) {
    users(
      filter: { rolesMask_lte: 4, userType: 1 }
      limit: $limit
      skip: $skip
    ) {
      _id
      firstName
      lastName
      fullName
      userType
    }
  }
`;

export default SENDERS_QUERY;
