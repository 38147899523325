import gql from 'graphql-tag';

export const PAUSE_SEQUENCE = gql`
  mutation PauseSequence {
    pauseSequence
    updateStoryStatus(status : "pause")
  }
`;

export const RESUME_SEQUENCE = gql`
  mutation resumeSequence {
    resumeSequence
    updateStoryStatus(status : "resume")
  }
`;
