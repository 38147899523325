/**
 * @author @Manimegalai-V
 * @since Feb 15 2022
 * @version V11.2
 */

import React from 'react';

type Props = {
  size?: '2xs' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl';
  title?:string;
};

const Help:React.FC<Props> = ({title,size='sm'}) => {
  return (
    <i
      className={`fas fa-question-circle ${' fa-' + size} ml-2 text-muted`}
      title={title}
      onMouseEnter={(e) => e.currentTarget.classList.remove('text-muted')}
      onMouseOut={(e) => e.currentTarget.classList.add('text-muted')}
    ></i>
  );
};
export default Help;
