import gql from 'graphql-tag';

export const CURRENT_USER_QUERY = gql`
  query me {
    me {
      _id
      firstName
      lastName
      fullName
      email
      title
      rolesMask
      roles
      workflowRoles
      customerId
      customer {
        _id
        name
        senderDailySendingLimit
      }
      dailySendingLimit
      onlineTime
      imageUrl
      sfdcIdentity
      salesforceId
      outreachIdentity
      salesloftIdentity
      koncertCadenceIdentity
      mixmaxIdentity
      salesforceUrl
      active
      koncertUserId
      products
      hasUser
      hasCustomer
      hasUserCoverage
      hasCustomerCoverage
      isFirstTimeUser
    }
  }
`;
