import gql from 'graphql-tag';

export const CUSTOMERSQUERYSTRING = gql`
  query allCustomers {
    customers(
      order: { name: ASC }
      where: { isCustomer: true, v3Enabled: true }
    ) {
      _id
      name
      logoUrl
      createdAt
    }
  }
`;

export const CUSTOMER_QUERY = gql`
  query customer {
    customer {
      _id
      name
      senderDailySendingLimit
      sequencePaused
      logoUrl
      createdAt
    }
  }
`;

export const SYNC_STATUS_QUERY = gql`
  query syncStatus {
    importSyncStatus
  }
`;
