import gql from 'graphql-tag';

export const ACCOUNTSELECTORS_QUERY = gql`
  query v3_Customer_AccountSelectors($limit: Int = 100, $skip: Int = 0) {
    accountSelectors(limit: $limit, skip: $skip) {
      _id
      customerId
      createdAt
      updatedAt
      lastChecked
      name
      selectorType
      totalMatchingAccountsCount
      totalCreatedAccountsCount
      storiesCount
    }
  }
`;
