import gql from 'graphql-tag';

export const ACCOUNT_ASSIGNMENT_LOGIC_QUERY = gql`
  query accountAssignmentLogic {
    accountAssignmentLogics {
      _id
      priority
      ruleSet {
        _id
      }
      senders {
        _id
        fullName
        imageUrl
        email
      }
    }
  }
`;

export const ACCOUNT_ASSIGNMENT_LOGIC_COUNT_QUERY = gql`
  query accountAssignmentLogicCount {
    accountAssignmentLogicsMeta {
      count
    }
  }
`;
