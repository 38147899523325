import gql from 'graphql-tag';
// import React from 'react';
// import { graphql } from 'graphql';

// export const EventLogQuery = (ComponentToWrap) => {
//   const WrappedComponent = (props) => {
//     const { data, loading, ...restProps } = props;

//     return (
//       <ComponentToWrap
//         loading={!!loading || data.loading}
//         eventLog={data.eventLog}
//         {...restProps}
//       />
//     );
//   };

//   return graphql(EVENT_LOG_QUERY_STRING, {
//     options: ({
//       match: {
//         params: { eventLogId },
//       },
//     }) => ({ variables: { eventLogId } }),
//   })(WrappedComponent);
// };

export const EVENT_LOG_QUERY_STRING = gql`
  query eventLog($eventLogId: ID!) {
    eventLog(where : {_id: $eventLogId}) {
      _id
      createdAt
      level
      event
      outcome
      path
      lineno
      label
      message
      user {
        _id
        email
      }
      customer {
        _id
        name
      }
      data
      backtrace
    }
  }
`;
