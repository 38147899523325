import {
  CHANGE_CLICK_DIALER_DATA,
  RESET_CLICK_DIALER_DATA,
  initialValues,
} from '../actions/actions';

const clickDialerReducer = (state = initialValues, action) => {
  switch (action.type) {
    case CHANGE_CLICK_DIALER_DATA:
      return { ...state, [action.name]: action.value };
    case RESET_CLICK_DIALER_DATA:
      return { ...action.payload };
    default:
      return state;
  }
};

export default clickDialerReducer;
