import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import CURRENT_USER_QUERY from './queries/CurrentUserQuery';
import { useDispatch } from 'react-redux';
import { signInUser } from '../store/actions/user.actions';
import authReducer from '../store/reducers/auth.reducers';
import { useSelector } from 'react-redux';
import Loading from './Layout/Loading';

const UserContext = React.createContext({});

export const UserProvider = (props) => {
  //fetch the current user ('me' request)
  const { data, loading, error, refetch } = useQuery(CURRENT_USER_QUERY);

  useDispatch(
    authReducer(
      useSelector((state) => state),
      signInUser('token', loading || error ? null : data?.me?.data[0])
    )
  );

  if (
    error?.message === 'GraphQL error: Invalid License' ||
    (!loading &&
      !error &&
      data?.me?.data &&
      data.me.data[0]?.userLicense &&
      !data.me.data[0].userLicense
        .split(',')
        .map((item) => item.trim())
        .includes('TC'))
  ) {
    throw new Error('Invalid license');
  }

  if (
    !loading &&
    !error &&
    (!data ||
      !data?.me ||
      !data?.me?.data ||
      !data?.me?.data[0] ||
      !data?.me?.data[0]?.userLicense)
  ) {
    throw new Error('500 Error');
  }

  if (loading) {
    return (
      <UserContext.Provider value={{ error, loading }}>
        <Loading />
      </UserContext.Provider>
    );
  }

  const user = !error && data?.me?.data ? data.me.data[0] : null;

  return (
    <UserContext.Provider value={{ user, loading, error, refetch }}>
      {!loading && props.children}
    </UserContext.Provider>
  );
};
export const UserConsumer = UserContext.Consumer;

export default UserContext;

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
