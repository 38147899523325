import gql from 'graphql-tag';

export const PLOTPOINT_CATEGORIES_QUERY = gql`
  query v3_Customer_StoryComponents_PlotPointCategories {
    plotPointCategories(filter: {}) {
      _id
      name
    }
  }
`;
