import React from 'react';

export const LinearGradientFill = ({ startColor, endColor, id }) => {
  return (
    <linearGradient id={id} x1="0%" y1="0%" x2="75%" y2="100%">
      <stop offset="0%" stopColor={startColor} stopOpacity="1" />
      <stop offset="100%" stopColor={endColor} stopOpacity="0.2" />
    </linearGradient>
  );
};
