import gql from 'graphql-tag';

export const ROE_QUERY = gql`
  query v3_Customer_Roe_Bases($limit: Int = 100, $skip: Int = 0) {
    baseRoes(limit: $limit, skip: $skip) {
      _id
      customerId
      createdAt
      updatedAt
      name
      days
      storiesCount
    }
  }
`;
