import gql from 'graphql-tag';

export const MARK_STORY_CONTACT_AS_SEEN = gql`
  mutation MarkStoryContactAsSeen($id: ID!, $lastViewedAt: DateTime) {
    updatestoryContact(id: $id, data: { lastViewedAt: $lastViewedAt }) {
      _id
      lastViewedAt
    }
  }
`;
