/**
 * @author @rkrishna-gembrill
 * @since Feb 25 2021
 * @version V11.0
 */
import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import GET_CONFIGURATIONS_QUERY from '../../queries/ConfigurationsQuery';
import UserContext from '../../UserContext';

const useConfigurations = () => {
  const { user } = useContext(UserContext);
  const { data = {}, error, loading, refetch } = useQuery(
    GET_CONFIGURATIONS_QUERY,
    {
      fetchPolicy: 'cache-first',
      skip: user?.isFirstTimeUser,
    }
  );
  return { data, error, loading, refetch };
};

export default useConfigurations;
