import { useLazyQuery, useQuery } from '@apollo/client';
import { useToggle } from '@koncert/shared-components';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { notify, showErrorMessage } from '../../../util';
import ConfirmModal from '../../Common/ConfirmModal';
import {
  ASSIGN_OR_MOVE_PROSPECT_TO_CADENCE_QUERY,
  FETCH_PROSPECT_QUERY,
} from '../../queries/ProspectsQuery';
import CadenceList from './CadenceList';

type LogATaskModalProps = {
  showModal: boolean;
  hideModal: () => void;
  prospectId: number;
  currentUserId: number;
  selectUserId: number;
};

const AssignOrMoveToCadenceModal = (props: LogATaskModalProps) => {
  const {
    showModal,
    hideModal,
    prospectId,
    currentUserId,
    selectUserId,
  } = props;
  const [cadenceId, setCadenceId] = useState<null | number>(null);
  const [cadenceName, setCadenceName] = useState<null | string>(null);
  const [cadenceError, setCadenceError] = useToggle(false);
  const [prospectDetails, setProspectDetails] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useToggle(false);
  const [isUserChanged, setIsUserChanged] = useState(false);

  useEffect(() => {
    setIsUserChanged(true);
  }, [selectUserId]);

  const { data } = useQuery(FETCH_PROSPECT_QUERY, {
    variables: {
      id: prospectId,
      currentUserId: selectUserId ? selectUserId : currentUserId,
      includeAssociationsQry: `includeAssociations[]=cadence`,
    },
    onCompleted: (response) => setProspectDetails(data?.prospect?.data[0]),
    onError: (response) => {
      showErrorMessage(
        response,
        'Failed to fetch prospect details',
        data,
        'failed_prospect_data_fetch'
      );
    },
  });

  const [
    assignProspect,
    { data: assignProspectData, loading: assignProspectLoading },
  ] = useLazyQuery(ASSIGN_OR_MOVE_PROSPECT_TO_CADENCE_QUERY, {
    onCompleted: (response) =>
      handleAssignProspectRequestCallback(response, true),
    onError: (response) => handleAssignProspectRequestCallback(response),
  });

  const handleAssignProspectRequestCallback = (
    response,
    requestSuccess?: boolean
  ) => {
    if (requestSuccess) {
      if (
        response?.assignOrMoveProspect?.data?.[0]?.assigned ||
        response?.assignOrMoveProspect?.data?.[0]?.isMoved
      ) {
        notify('Prospect added to Cadence successfully!', 'success');
      } else {
        notify(response.assignOrMoveProspect.data[0].reason, 'error');
      }
      setShowConfirmModal(false);
      hideModal();
    } else {
      showErrorMessage(
        response,
        'Sorry! Failed to assign the prospect in cadence.',
        assignProspectData,
        'assign_prospect_failed'
      );
    }
  };

  const handleProspectToCadenceAction = () => {
    if (!cadenceId) {
      setCadenceError(true);
    } else {
      if (prospectDetails?.campaignName) {
        setShowConfirmModal(true);
      } else {
        const input = {};
        if (currentUserId !== selectUserId) {
          input['user'] = { id: selectUserId };
        }
        assignProspect({
          variables: {
            prospectId,
            cadenceId,
            input,
            action: 'assignToCadence',
          },
        });
      }
    }
  };

  return (
    <Modal size="md" isOpen={showModal} centered>
      <ModalHeader toggle={() => hideModal()}>
        <i className="fas fa-plus mr-2"></i>Assign Prospect to Cadence
      </ModalHeader>
      <ModalBody className="text-center">
        <Form name="assignProspectToCadence">
          <p className="mb-2 text-nowrap text-center">
            Choose the Cadence to which the prospect need to be Assigned.
          </p>

          <FormGroup row className="mt-1 mb-0">
            <Label
              for="assign_prospect_to_cadence"
              sm={3}
              className="text-right pr-0"
            >
              Cadence<span className="text-danger">*</span>
            </Label>
            <Col sm={8}>
              <div className="wd-lg">
                <CadenceList
                  value={cadenceId}
                  onChange={(value, name) => {
                    setCadenceId(value);
                    setCadenceName(name);
                  }}
                  placeHolder="Add to Cadence"
                  currentUserId={selectUserId ? selectUserId : currentUserId}
                  isUserChanged={isUserChanged}
                  setIsUserChanged={setIsUserChanged}
                />
              </div>
            </Col>
          </FormGroup>
          <p className="text-danger text-sm">
            {cadenceError && 'Please select a Cadence to assign'}
          </p>
        </Form>
      </ModalBody>
      <ModalFooter className="card-footer">
        <Button
          color="success"
          className="text-white"
          onClick={handleProspectToCadenceAction}
          disabled={assignProspectLoading}
        >
          <i
            className={`${
              assignProspectLoading ? 'fas fa-spinner fa-spin' : 'fa fa-check'
            } text-white mr-2`}
          />
          {assignProspectLoading ? 'Wait...' : 'OK'}
        </Button>
      </ModalFooter>
      {showConfirmModal && (
        <ConfirmModal
          showConfirmModal={showConfirmModal}
          handleCancel={() => setShowConfirmModal()}
          handleConfirm={() => {
            const input = {};
            if (currentUserId !== selectUserId) {
              input.userId = selectUserId;
            }
            assignProspect({
              variables: {
                prospectId,
                cadenceId,
                input,
                action: 'moveToCadence',
              },
            });
          }}
          showConfirmBtnSpinner={assignProspectLoading}
          confirmBtnText="OK"
          confirmBtnIcon="fas fa-check"
        >
          The prospect is already in cadence{' '}
          <strong>{prospectDetails?.campaignName}</strong>, do you still want to
          move this prospect to cadence <strong>{cadenceName}</strong>?.
          <br />
          <br />
          Click OK to continue or click cancel to skip from moving to another
          cadence.
        </ConfirmModal>
      )}
    </Modal>
  );
};

export default AssignOrMoveToCadenceModal;
