/**
 * @author Albert
 * @version V11.2
 */
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { showErrorMessage } from '../../../util/index';
import { FETCH_EMAIL_ACCOUNT_QUERY } from '../../queries/SettingsQuery';

const useEmailAccounts = (
  userId: number,
  accountId?: number,
  mode?: string
) => {
  const userFilter = `filter[user][id]=${userId}${
    accountId ? `&filter[id]=${accountId}` : ''
  }&includeAssociations[]=emailSignature`;
  const [emailAccounts, setEmailAccounts] = useState();
  const [primaryEmail, setPrimaryEmail] = useState();

  const { data, loading, error, refetch } = useQuery(
    FETCH_EMAIL_ACCOUNT_QUERY,
    {
      variables: {
        emailFilter: userFilter,
      },
      notifyOnNetworkStatusChange: true,
      skip: mode === 'single' && !accountId,
      onCompleted: (response) =>
        handleFetchEmailAccountsRequestCallback(response, true),
      onError: (response) => handleFetchEmailAccountsRequestCallback(response),
    }
  );

  const handleFetchEmailAccountsRequestCallback = (
    response,
    requestSuccess?: boolean
  ) => {
    if (requestSuccess) {
      const emailAccountsWithSignature = response?.Email?.data.map(
        (emailAccount) => {
          const emailSignature = response?.Email?.includedAssociations?.emailSignature?.find(
            (signature) =>
              signature?.id ===
              emailAccount?.associations?.emailSignature?.[0]?.id
          );
          return {
            ...emailAccount,
            emailSignatureData: emailSignature?.content,
          };
        }
      );
      setEmailAccounts(emailAccountsWithSignature);
      const primaryEmail = emailAccountsWithSignature.find(
        (email) => email.isPrimary
      );
      setPrimaryEmail(primaryEmail);
    } else {
      showErrorMessage(
        response,
        'Failed to fetch email account details',
        data,
        'fetch_email_accounts'
      );
    }
  };

  return { data, loading, error, refetch, emailAccounts, primaryEmail };
};

export default useEmailAccounts;
