import React from 'react';

export const Footer = (props) => {
  const year = new Date().getFullYear();

  return (
    <footer
      className="footer-container"
      style={props.user?.isFirstTimeUser ? { marginLeft: '0' } : {}}
    >
      <span>
        &copy; {year} - {props.productName} <sup>&reg;</sup>
      </span>
    </footer>
  );
};
