import gql from 'graphql-tag';

export const TYPETRIGGERED_ELEMENTS_QUERY = gql`
  query v3_Customer_StoryComponents_Elements_TypeTriggereds(
    $triggerDataPoint: String!
    $senderId: ID
    $limit: Int = 10
    $skip: Int = 0
    $order: TypeTriggeredElementQueryArgsSort = { weight: DESC }
  ) {
    typeTriggeredElements(
      where: {
        senderId: $senderId
        triggerDataPoints: { _type: $triggerDataPoint }
      }
      limit: $limit
      skip: $skip
      order: $order
    ) {
      _id
      type
      triggerDataPointsMeta {
        count
      }
      senderId
      triggerDataPoints {
        _type
        value
      }
      text
      logicalOperator
    }
  }
`;

export const VALUETRIGGERED_ELEMENTS_QUERY = gql`
  query v3_Customer_StoryComponents_Elements_ValueTriggereds(
    $triggerDataPoint: String!
    $senderId: ID
    $limit: Int = 10
    $skip: Int = 0
    $order: ValueTriggeredElementQueryArgsSort = { weight: DESC }
  ) {
    valueTriggeredElements(
      where: {
        senderId: $senderId
        triggerDataPoints: { _type: $triggerDataPoint }
      }
      limit: $limit
      skip: $skip
      order: $order
    ) {
      _id
      type
      triggerDataPointsCount
      senderId
      triggerDataPoints {
        _type
        value
      }
      text
      logicalOperator
    }
  }
`;

export const ELEMENTS_COUNT_QUERY = gql`
  query v3_Customer_StoryComponents_Elements_Count($filter: ElementQueryArgs) {
    elementsMeta(where: $filter) {
      count
    }
  }
`;

export const ELEMENT_QUERY = gql`
  query v3_Customer_StoryComponents_Element($id: ID!) {
    elements(where: { _id: $id }) {
      _id
      type
      text
      senderId
      weight
      plotPointAsDefaultId
      plotPointAsAdditionalId
      triggerDataPoints {
        _id
        _type
        value
      }
      sender {
        _id
        fullName
      }
    }
    triggerDataPointsMeta(where: { _id: $id }) {
      count
    }
  }
`;

export const ELEMENTS_BY_CATEGORY_QUERY = gql`
  query elements_by_category_query($plotPointCategoryId: ID!) {
    elements(where: { plotPointCategoryId: $plotPointCategoryId }) {
      _id
      type
      text
      senderId
      weight
      plotPointAsDefaultId
      plotPointAsAdditionalId
      plotPointCategoryId
      triggerDataPoints {
        _id
        _type
        value
      }
      sender {
        _id
        fullName
      }
    }
    triggerDataPointsMeta(
      where: { plotPointCategoryId: $plotPointCategoryId }
    ) {
      count
    }
  }
`;
