import React from 'react';
import { PLEASE_CONTACT_CONNECTLEADER_SUPPORT } from 'apps/cadence/src/util';
import { KoncertLogodiv, ErrorLinks } from '@koncert/shared-components';

const LicenseError = (props) => (
  <div className="d-flex flex-column justify-content-center h-100">
    <div className="text-center mb-4">
      <div className="mb-3">
        <KoncertLogodiv customWidth="350" />
      </div>
      <div className="text-lg mb-3">
        <i className="fas fa-ban text-muted mr-2"></i>
        Invalid License
      </div>
      <p className="lead m-0">
        Sorry! Your license is not setup. {PLEASE_CONTACT_CONNECTLEADER_SUPPORT}.
      </p>
    </div>
    <ErrorLinks props={props} />
  </div>
);

export default LicenseError;
