import React, { useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { createApolloClient } from '../apollo/createApolloClient';
import CurrentUserQuery from '../components/queries/LicenseUserQuery';
import { ApiUrlAndTokenContext } from '../auth/ApiUrlAndTokenProvider';
import Loading from './Layout/Loading';

const LicenseUserContext = React.createContext({});

export const LicenseUserProvider = (props) => {
  //fetch the current user ('me' request)
  const client = useMemo(() => createApolloClient(), []);
  const { token } = useContext(ApiUrlAndTokenContext);
  const [licenseUserQuery, { data, loading, error }] = useLazyQuery(
    CurrentUserQuery,
    {
      client,
      context: {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      },
      onCompleted: () => {
        handleLicenseUserCallback();
      },
      onError: () => {
        handleLicenseUserCallback();
      },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => licenseUserQuery(), []);

  const handleLicenseUserCallback = () => {
    if (error?.networkError?.statusCode === 500) {
      throw new Error('500 Error');
    }

    // If the user is inactive move them to the 500 error page with the message user is inactive message
    if (error?.message === 'Invalid License') {
      throw new Error('Inactive User Error');
    }

    if (
      error?.graphQLErrors &&
      ['INVALID_DATA', 'SYSTEM_ERROR'].includes(
        error.graphQLErrors[0]?.errorCode
      )
    ) {
      throw new Error('500 Error');
    }

    if (
      !loading &&
      !error &&
      (!data || !data?.me || !data?.me?.data || !data?.me?.data?.[0])
    ) {
      throw new Error('500 Error');
    }

    if (
      error?.message === 'GraphQL error: Invalid License' ||
      (!loading &&
        !error &&
        (data?.me?.data?.[0]?.products === '' ||
          !data?.me?.data?.[0]?.products ||
          !data?.me?.data?.[0]?.products.includes('TC')))
    ) {
      throw new Error('No License Error');
    }
  };

  if (loading) {
    return (
      <LicenseUserContext.Provider value={{ error, loading }}>
        <Loading />
      </LicenseUserContext.Provider>
    );
  }

  const user = !error && data?.me?.data ? data.me.data[0] : null;

  return (
    <LicenseUserContext.Provider
      value={{ user, loading, error, refetch: licenseUserQuery }}
    >
      {!loading && props.children}
    </LicenseUserContext.Provider>
  );
};
export const LicenseUserConsumer = LicenseUserContext.Consumer;

export default LicenseUserContext;

LicenseUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
