import gql from 'graphql-tag';

export const UPDATE_SENDER_USERTYPE = gql`
  mutation UpdateSenderUserType($id: ID!, $userType: Int!) {
    updateuser(id: $id, data: { userType: $userType }) {
      _id
    }
  }
`;

export const UPDATE_SENDER_DAILY_SENDING_LIMIT = gql`
  mutation UpdateSenderUserType($id: ID!, $dailySendingLimit: Int!) {
    updateuser(id: $id, data: { dailySendingLimit: $dailySendingLimit }) {
      _id
    }
  }
`;

export const UPDATE_SENDER_ROLE = gql`
  mutation UpdateSenderRole($id: ID!, $rolesMask: Int!) {
    updateuser(id: $id, data: { rolesMask: $rolesMask }) {
      _id
    }
  }
`;
export const RESEND_CONFIRMATION_EMAIL = gql`
  mutation reSendConfirmationEmail($id: ID!) {
    reSendUserConfirmationEmail(id: $id) {
      id
    }
  }
`;

export default UPDATE_SENDER_USERTYPE;
