/**
 * @author @Panimaya-Albert
 * @version V11.2
 */
import { gql } from '@apollo/client';

const FETCH_REMINDERS_LIST_QUERY = gql`
  query($remindersFilter: String!) {
    reminders(remindersFilter: $remindersFilter)
      @rest(type: "Reminders", path: "tasks/reminder?{args.remindersFilter}") {
      response
      paging
      data
      includedAssociations
    }
  }
`;

export default FETCH_REMINDERS_LIST_QUERY;
