import React from 'react';
import Error500 from '../Pages/Error500';
import LicenseError from '../Pages/LicenseError';
import TimeoutError from '../Pages/TimeoutError';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      hasLicenseError: false,
      hasTimeoutError: false,
      isInactiveUser: false,
      isNoLicenseUser: false,
    };
  }

  static getDerivedStateFromError(error) {
    if (error.message === 'Inactive User Error') {
      return { hasError: true, isInactiveUser: true };
    } else if (
      error.message === 'No License Error' ||
      error.message === 'Invalid license'
    ) {
      return { hasError: true, isNoLicenseUser: true };
    } else if (error.message === 'Timeout') {
      return { hasTimeoutError: true };
    } else {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasLicenseError) {
      // license error.
      return <LicenseError {...this.props} />;
    }
    if (this.state.hasTimeoutError) {
      // Timeout error
      return <TimeoutError {...this.props} />;
    }
    if (this.state.isNoLicenseUser) {
      // No license error
      return <Error500 {...this.props} isNoLicenseUser={true} />;
    }
    if (this.state.isInactiveUser) {
      // Inactive user error
      return <Error500 {...this.props} isInactiveUser={true} />;
    }
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error500 {...this.props} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
