/**
 * @author Albert
 * @version V11.2
 */
import { useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GET_LOOKUP_VALUE_QUERY } from '../../queries/PendingCallsQuery';
import { showErrorMessage } from '../../../util/index';
import UserContext from '../../UserContext';

const useLookupData = () => {
  const { user } = useContext(UserContext);
  const { data, error, loading, refetch } = useQuery(GET_LOOKUP_VALUE_QUERY, {
    fetchPolicy: 'cache-first',
    skip: user?.isFirstTimeUser,
    variables: {
      lookupsFilter: `filter[lookupName]=:[trucadence_hide_import_from_crm,trucadence_hide_import_from_file,bulk_prospects_delete_limit,browser_session_time_out,import_ajax_timeout,allow_blank_values_in_prospect_edit_fields,enable_crm_log_dialing_session,disable_call_logs,allowed_date_formats_for_import,allowed_timestamp_formats_for_import]`,
    },
    onError: (error) => {
      showErrorMessage(
        error,
        'Sorry! Failed to fetch lookup values',
        data,
        'lookup_values_error'
      );
    },
  });

  const lookup = useMemo(
    () =>
      data?.lookup?.data.reduce((acc, item) => {
        acc[item.lookupName] = item.lookupValue;
        return acc;
      }, {}) ?? null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return { data, error, loading, refetch, lookup };
};

export default useLookupData;
