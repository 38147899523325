import gql from 'graphql-tag';

export const CREATE_STATIC_ELEMENT = gql`
  mutation CreateStaticElement(
    $plotPointAsDefaultId: ID
    $plotPointAsAdditionalId: ID
    $weight: Int!
    $text: String!
    $senderId: ID
    $plotPointCategoryId: ID!
  ) {
    createstaticElement(
      data: {
        plotPointAsDefaultId: $plotPointAsDefaultId
        plotPointAsAdditionalId: $plotPointAsAdditionalId
        weight: $weight
        text: $text
        senderId: $senderId
        plotPointCategoryId: $plotPointCategoryId
      }
    ) {
      _id
    }
  }
`;

export const CREATE_TYPETRIGGERED_ELEMENT = gql`
  mutation CreateTypeTriggeredElement(
    $plotPointAsDefaultId: ID
    $plotPointAsAdditionalId: ID
    $plotPointCategoryId: ID!
    $weight: Int!
    $dataPoints: [DataPointDTO!]!
    $text: String!
    $senderId: ID
    $logicalOperator: String
  ) {
    createTypeTriggeredElement(
      data: {
        plotPointAsDefaultId: $plotPointAsDefaultId
        plotPointAsAdditionalId: $plotPointAsAdditionalId
        weight: $weight
        triggerDataPoints: $dataPoints
        text: $text
        senderId: $senderId
        plotPointCategoryId: $plotPointCategoryId
        logicalOperator: $logicalOperator
      }
    ) {
      _id
    }
  }
`;

export const CREATE_VALUETRIGGERED_ELEMENT = gql`
  mutation CreateValueTriggeredElement(
    $plotPointAsDefaultId: ID
    $plotPointAsAdditionalId: ID
    $weight: Int!
    $dataPoints: [DataPointDTO!]!
    $text: String!
    $senderId: ID
    $plotPointCategoryId: ID!
    $logicalOperator: String
  ) {
    createValueTriggeredElement(
      data: {
        plotPointAsDefaultId: $plotPointAsDefaultId
        plotPointAsAdditionalId: $plotPointAsAdditionalId
        weight: $weight
        triggerDataPoints: $dataPoints
        text: $text
        senderId: $senderId
        plotPointCategoryId: $plotPointCategoryId
        logicalOperator: $logicalOperator
      }
    ) {
      _id
    }
  }
`;
