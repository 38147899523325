import { gql } from '@apollo/client';

const currentUserQuery = gql`
  query {
    me @rest(type: "User", path: "users/me") {
      data
    }
  }
`;

export default currentUserQuery;
