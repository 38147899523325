/**
 * @author @Panimaya-Albert
 * @version V11.2
 */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCadences } from '../../../store/actions/actions';
import DropDown from '../../Common/DropDown';

type CadenceListProps = {
  currentUserId: number;
  isUserChanged: boolean;
  setIsUserChanged: Dispatch<SetStateAction<boolean>>;
  value: null | number;
  multiselect?: boolean;
  onChange: (arg0: number, arg1: string) => void;
  placeHolder: string;
  maxHeight?: string;
};

const CadenceList = (props: CadenceListProps) => {
  const {
    currentUserId,
    isUserChanged,
    setIsUserChanged,
    value,
    multiselect,
    onChange,
    placeHolder,
    maxHeight,
  } = props;
  const dispatch = useDispatch();
  const cadences = useSelector((state) => state.cadences);
  const dropDownRef = useRef();
  const [cadenceList, setCadenceList] = useState([]);

  const handleFetchCadences = () => {
    dispatch(getAllCadences(currentUserId));
  };

  useEffect(() => {
    if (!cadences.fetchedAll || isUserChanged) {
      setCadenceList([]);
      handleFetchCadences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserChanged]);

  useEffect(() => {
    if (cadences && !cadences.loading) {
      const myCadences = cadences?.data
        .filter(
          (cad) =>
            (cad.status === 'ACTIVE' || cad.status === 'NEW') &&
            !(
              cad.isPassedEmailTouch === 'passedDateTime' ||
              (cad.emailTouchCount > 0 &&
                cad.emailTouchCount !== cad.templateActiveCount)
            ) &&
            cad?.associations?.touch?.length > 0 &&
            cad.associations.user[0].id === currentUserId
        )
        .map((item, index) => {
          return {
            text: item.name,
            value: item.id,
            active: false,
            header: index === 0 ? 'My Cadences' : '',
          };
        });

      const sharedCadences = cadences?.data
        .filter(
          (cad) =>
            (cad.status === 'ACTIVE' || cad.status === 'NEW') &&
            !(
              cad.isPassedEmailTouch === 'passedDateTime' ||
              (cad.emailTouchCount > 0 &&
                cad.emailTouchCount !== cad.templateActiveCount)
            ) &&
            cad?.associations?.touch?.length > 0 &&
            cad.associations.user &&
            cad.associations.user[0].id !== currentUserId
        )
        .map((item, index) => {
          return {
            text: item.name,
            value: item.id,
            active: false,
            header: index === 0 ? 'Shared Cadences' : '',
          };
        });

      setCadenceList(myCadences.concat(sharedCadences));
      if (isUserChanged) setIsUserChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cadences]);

  return (
    <DropDown
      name="cadence"
      data={cadenceList}
      ref={dropDownRef}
      value={value}
      onChange={onChange}
      placeHolder={cadences.error ? 'Failed to fetch' : placeHolder}
      multiselect={multiselect}
      handleRefresh={handleFetchCadences}
      loading={cadences.loading}
      error={cadences.error}
      maxHeight={maxHeight}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    />
  );
};

export default CadenceList;
