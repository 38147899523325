import gql from 'graphql-tag';

export const ACCOUNT_SELECTOR_STATISTICS_QUERY_STRING = gql`
  query accountSelectorStats(
    $accountSelectorArg: AccountSelectorCreateDTO!
    $selectorFilterCriteriaArgs: [SelectorFilterCriteriaCreateDTO!]
  ) {
    accountSelectorStats(
      accountSelectorArg: $accountSelectorArg
      selectorFilterCriteriaArgs: $selectorFilterCriteriaArgs
    ) {
      totalAccounts
      matchedAccounts
      totalContacts
      matchedContacts
    }
  }
`;
