import gql from 'graphql-tag';

export const CONTACTSELECTORS_QUERY = gql`
  query v3_Customer_ContactSelectors($limit: Int = 100, $skip: Int = 0) {
    contactSelectors(limit: $limit, skip: $skip) {
      _id
      customerId
      createdAt
      updatedAt
      lastChecked
      name
      selectorType
      totalMatchingContactsCount
      totalCreatedContactsCount
      storiesCount
    }
  }
`;
