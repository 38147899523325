/**
 * @author @Panimaya-Albert
 * @version V11.2
 */
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { FETCH_PROSPECTS_DATA_QUERY } from '../../queries/EmailsQuery';
import DropDown from '../../Common/DropDown';
import ClButton from '../../Common/Button';
import UserList from '../../Common/UserList';
import { showErrorMessage } from '../../../util/index';

const SelectProspectModal = ({
  showModal,
  closeModal,
  handleAction,
  currentUserId,
  selectedUserId,
  hideUserList,
  disableUserList,
}) => {
  const [prospectsData, setProspectsData] = useState([]);
  const [allProspectsOffset, setAllProspectsOffset] = useState(0);
  const [allProspectsLimit] = useState(200);
  const [selectedProspects, setSelectedProspects] = useState();
  const [selectedProspectsName, setSelectedProspectsName] = useState();
  const [validationState, setValidationState] = useState(false);
  const [selectUserId, setSelectUserId] = useState(
    selectedUserId ? selectedUserId : currentUserId
  );

  const handleUserChange = (value) => {
    setSelectUserId(value);
    setProspectsData([]);
    setAllProspectsOffset(0);
  };

  const handleSubmit = () => {
    if (selectedProspects.length === 0) {
      setValidationState(true);
    } else {
      setValidationState(false);
      handleAction(selectedProspects, selectUserId, selectedProspectsName);
    }
  };

  useEffect(() => {
    fetchAllProspectsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProspectsOffset]);

  useEffect(() => {
    if (showModal) {
      setSelectedProspects([]);
      setValidationState(false);
    }
  }, [showModal]);

  const [
    fetchAllProspectsData,
    { data: prospectData, loading: prospectsLoading, error },
  ] = useLazyQuery(FETCH_PROSPECTS_DATA_QUERY, {
    variables: {
      userFilter: `filter[user][id]=${selectUserId}`,
      limit: allProspectsLimit,
      offset: allProspectsOffset,
    },
    onCompleted: (response) =>
      handleFetchProspectsRequestCallback(response, true),
    onError: (response) => handleFetchProspectsRequestCallback(response),
  });

  const handleFetchProspectsRequestCallback = (response, requestSuccess) => {
    if (requestSuccess) {
      const data = response?.prospects?.data.map((prospect) => {
        return {
          value: prospect.id,
          text: prospect.contactName,
          emailId: prospect.email,
        };
      });
      setProspectsData([...prospectsData, ...data]);
      // check if the fatched prospects count is less than the totalCount of prospects
      if (
        (allProspectsOffset + 1) * allProspectsLimit <
        response?.prospects?.paging.totalCount
      ) {
        // if true add one to the offset
        setAllProspectsOffset(allProspectsOffset + 1);
      }
    } else {
      showErrorMessage(
        response,
        'Failed to fetch prospects data',
        prospectData,
        'failed_prospects-fetch'
      );
    }
  };

  return (
    <div>
      <Modal isOpen={showModal} centered>
        <ModalHeader toggle={() => closeModal()} className="pt-4">
          <i className="fa fas fa-user-plus text-warning mr-2"></i>Select a
          Prospect
        </ModalHeader>
        <ModalBody>
          {!hideUserList && (
            <FormGroup row className="d-flex align-items-center mt-1 pt-2">
              <Label for="select_users" md={3} className="pr-0">
                User
              </Label>
              <Col
                md={8}
                className="pl-0 d-flex flex-column justify-content-start"
              >
                <UserList
                  value={selectUserId}
                  onChange={handleUserChange}
                  placeHolder={'Select Users'}
                  disabled={disableUserList}
                />
              </Col>
            </FormGroup>
          )}
          <FormGroup row className="d-flex align-items-center mb-0">
            <Label for="select_prospects" md={3} className="pr-0">
              Prospect
            </Label>
            <Col
              md={8}
              className="pl-0 d-flex flex-column justify-content-start"
            >
              <DropDown
                id="select_a_prospects"
                name="prospects"
                data={prospectsData}
                value={selectedProspects}
                placeHolder={'Select a Prospect'}
                loading={
                  prospectsData &&
                  prospectsData.length < 200 &&
                  prospectsLoading
                }
                error={error}
                handleRefresh={() => {
                  setProspectsData([]);
                  setSelectedProspects([]);
                  setAllProspectsOffset(0);
                }}
                onChange={(value, name) => {
                  setSelectedProspects(value);
                  setSelectedProspectsName(name);
                }}
              />
              <p className="text-danger text-sm">
                {validationState && 'Please select a prospect'}
              </p>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="p-3">
          <ClButton
            type="submit"
            color="primary"
            className="text-white"
            icon="fa fa-chevron-right"
            title="Next"
            onClick={handleSubmit}
          >
            Next
          </ClButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SelectProspectModal;
