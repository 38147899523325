import gql from 'graphql-tag';

export const UPDATE_PLOTPOINT = gql`
  mutation UpdatePlotPoint(
    $id: ID!
    $name: String!
    $position: Int!
    $plotPointCategoryId: ID!
  ) {
    updateplotPoint(
      id: $id
      data: {
        name: $name
        position: $position
        plotPointCategoryId: $plotPointCategoryId
      }
    ) {
      _id
    }
  }
`;
