import React from 'react';
import { Progress } from 'reactstrap';

type Props = {
    pageName: string;
  };

const ProgressLoader : React.FC<Props> = (props) => {
    const {pageName}=props
  return (
    <Progress animated striped value="100">
      {`Loading ${pageName}...`}
    </Progress>
  );
};

export default ProgressLoader;