/**
 * @author Albert
 * @version V11.2
 */
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_MAIL_MERGE_VARIABLES } from '../../queries/EmailTemplatesQuery';

const useMailMergeVariables = () => {
  const { data, error, loading, refetch } = useQuery(
    FETCH_MAIL_MERGE_VARIABLES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first',
    }
  );
  const mailMergeVariables = useMemo(
    () =>
      data?.mailmergeVariables?.data
        ? data.mailmergeVariables.data.mail_merge
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return { data, error, loading, refetch, mailMergeVariables };
};

export default useMailMergeVariables;
