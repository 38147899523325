/**
 * @author @Panimaya-Albert
 * @version V11.2
 */
import React from 'react';

type QuickActionProps = {
  actionIcon: string;
  actionTitle: string;
  actionColumnSize: 4 | 3;
  action: () => void;
  showLabel: boolean;
  label: string;
};

const QuickAction = (props: QuickActionProps) => {
  const {
    actionIcon,
    actionTitle,
    action,
    showLabel,
    label,
  } = props;
  return (
    <div
      title={actionTitle}
      className="pointer mb-1 d-flex flex-column justify-content-center align-items-center"
      onClick={() => action()}
    >
      <span className="circle-icon-action d-flex mx-auto mb-1 border border-regent-gray align-items-center justify-content-center rounded-circle">
        <i className={actionIcon}></i>
      </span>
      {showLabel && <small>{label}</small>}
    </div>
  );
};

export default QuickAction;
