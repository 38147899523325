import gql from 'graphql-tag';

export const CREATE_BASE_ROE = gql`
  mutation CreateRoe($name: String!, $days: Int!) {
    createbaseRoe(data: { name: $name, days: $days }) {
      _id
    }
  }
`;

export default CREATE_BASE_ROE;
