import gql from 'graphql-tag';

export const CREATE_CONTACT_SELECTOR = gql`
  mutation CreateContactSelector(
    $contactSelectorArgs: ContactSelectorCreateDTO!
    $selectorFilterCriteriaArgs: [SelectorFilterCriteriaCreateDTO!]
  ) {
    createContactSelector(
      contactSelectorArgs: $contactSelectorArgs
      selectorFilterCriteriaArgs: $selectorFilterCriteriaArgs
    )
  }
`;

export default CREATE_CONTACT_SELECTOR;
