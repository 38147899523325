/**
 * @author @Anbarasan
 * @version V11.0
 */
import { gql } from '@apollo/client';
export const FETCH_FIELDS_QUERY = gql`
  query($limit: String!, $offset: String!, $filterMapping: String!) {
    fields(limit: $limit, offset: $offset, filterMapping: $filterMapping)
      @rest(
        type: "Fields"
        path: "fields?page[limit]={args.limit}&page[offset]={args.offset}{args.filterMapping}&includeAssociations[]=fieldDropdownValues"
      ) {
      data
      includedAssociations
    }
  }
`;

export const FETCH_FIELDS_ORDER_QUERY = gql`
  query {
    fields @rest(type: "FieldsOrder", path: "fieldOrder") {
      response
      data
    }
  }
`;

export const SAVE_FIELD_ORDER_QUERY = gql`
  query {
    fields(input: $input)
      @rest(type: "FieldsOrder", path: "fieldOrder", method: "POST") {
      response
      data
    }
  }
`;

export const UPDATE_FIELD_ORDER_QUERY = gql`
  query {
    fields(input: $input)
      @rest(type: "FieldsOrder", path: "fieldOrder", method: "PUT") {
      response
      data
    }
  }
`;

const FETCH_CL_CRM_FIELD_MAPPING_QUERY = gql`
  query(
    $limit: String!
    $offset: String!
    $filterMapping: String!
    $includeAssociationsQry: String!
  ) {
    fields(
      limit: $limit
      offset: $offset
      filterMapping: $filterMapping
      includeAssociationsQry: $includeAssociationsQry
    )
      @rest(
        type: "Fields"
        path: "fieldMappings?{args.includeAssociationsQry}&page[limit]={args.limit}&page[offset]={args.offset}{args.filterMapping}"
      ) {
      includedAssociations
      data
    }
  }
`;
export default FETCH_CL_CRM_FIELD_MAPPING_QUERY;
