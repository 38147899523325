export const ThemeColors = {
  colorMidnightExpress: '#0c1b37',
  colorDarkMidnightExpress: '#09162d',
  colorLightScarlet: '#ea7765',
  colorSunset: '#f15b28',
  colorKoncertWhite: '#f5f5f5',
  colorPrimaryShade: '#273a5c',
  colorSecondaryShade: '#2a3557',
  colorPersianBlue: '#5b45e0',
  gradientBloodOrangeStart: '#fbab44',
  gradientBloodOrangeEnd: '#f15b28',
  gradientBloodOrangeLightStart: '#f6c46a',
  gradientBloodOrangeLightEnd: '#fb884d',
  gradientTurquoiseDreamStart: '#33ebc5',
  gradientTurquoiseDreamEnd: '#21bfe6',
  gradientPurpleRainStart: '#96a1ff',
  gradientPurpleRainEnd: '#4e4efb',
  gradientBalticSeaStart: '#67c4ff',
  gradientBalticSeaEnd: '#1f90ff',
  gradientGothicSkyStart: '#3b3a40',
  gradientGothicSkyEnd: '#62597a',
};

export const Colors = {
  Ocean: '#f15b28',
  TrueBlue: '#fbab44',
  Cyan: '#fac47e',
  Powder: '#afe3f9',
  Sky: '#d2f2ff',
  White: '#ffffff',
  Charcoal: '#4c4c4c',
  Rock: '#666666',
  Grey: '#999999',
  Wolf: '#cccccc',
  Mako: '#e0e0e0',
  Silver: '#f4f4f4',
  Grass: '#37c776',
  Mint: '#cafdda',
  Perry: '#8597ff',
  Mist: '#e1eaff',
  Poppy: '#f45b53',
  Rouge: '#ffe1e1',
  Orange: '#fd8c26',
};
