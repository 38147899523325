import gql from 'graphql-tag';

export const CREATE_USER_QUERY = gql`
  query userCreation {
    createUser {
      _id
    }
  }
`;

export const UPDATE_USER_QUERY = gql`
  mutation userCreation($id: Int!, $name: String!) {
    updateUser(id: $id, data : {name: $name}) {
      _id
    }
  }
`;

export const CREATE_CUSTOMER_QUERY = gql`
  query customerCreation {
    createCustomer {
      _id
    }
  }
`;
