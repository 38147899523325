import gql from 'graphql-tag';

export const CONTACT_SELECTOR_STATISTICS_QUERY_STRING = gql`
  query contactSelectorStats(
    $contactSelectorArg: ContactSelectorCreateDTO!
    $selectorFilterCriteriaArgs: [SelectorFilterCriteriaCreateDTO!]
  ) {
    contactSelectorStats(
      contactSelector: $contactSelectorArg
      selectorFilterCriterias: $selectorFilterCriteriaArgs
    ) {
      totalContacts
      matchedContacts
    }
  }
`;
