import gql from 'graphql-tag';

export const CREATE_ACCOUNT_SELECTOR = gql`
  mutation CreateAccountSelector(
    $accountSelectorArgs: AccountSelectorCreateDTO!
    $selectorFilterCriteriaArgs: [SelectorFilterCriteriaCreateDTO!]
  ) {
    createAccountSelector(
      accountSelectorArgs: $accountSelectorArgs
      selectorFilterCriteriaArgs: $selectorFilterCriteriaArgs
    )
  }
`;

export default CREATE_ACCOUNT_SELECTOR;
