import gql from 'graphql-tag';
// import React from 'react'
// import { graphql } from "@apollo/react-hoc";

// export const AccountDataQuery = (ComponentToWrap) => {
//   const WrappedComponent = (props) => {
//     const { data, loading, ...restProps } = props
//     return <ComponentToWrap loading={!!loading || data.loading} account={data.v3_AccountData || null} {...restProps} />
//   }

//   return graphql(ACCOUNTDATAQUERYSTRING, {
//     options: ({ accountId }) => ({ variables: { accountId } })
//   })(WrappedComponent)
// }

export const ACCOUNTDATAQUERYSTRING = gql`
  query($accountId: ID!) {
    accountData(where : {_id: $accountId}) {
      _id
      name {
        _id
        value
        dataSource
        _type
      }
      domains {
        _id
        value
        dataSource
        _type
      }
      industries {
        _id
        value
        dataSource
        _type
      }
      website {
        _id
        value
        dataSource
        _type
      }
      sectors {
        _id
        value
        dataSource
        _type
      }
      tags {
        _id
        value
        dataSource
        _type
      }
      employeeCountRanges {
        _id
        value
        dataSource
        _type
      }
      usesApps {
        _id
        value
        dataSource
        _type
      }
      addresses {
        _id
        value
        dataSource
        _type
      }
      numberOfEmployees {
        _id
        value
        dataSource
        _type
      }
      legalName {
        _id
        value
        dataSource
        _type
      }
      revenue {
        _id
        value
        dataSource
        _type
      }
      revenueRange {
        _id
        value
        dataSource
        _type
      }
      haveRaised {
        _id
        value
        dataSource
        _type
      }
      haveRaisedRange {
        _id
        value
        dataSource
        _type
      }
      salesGrowth {
        _id
        value
        dataSource
        _type
      }
      employeeGrowth {
        _id
        value
        dataSource
        _type
      }
      alexaUsRank {
        _id
        value
        dataSource
        _type
      }
      alexaGlobalRank {
        _id
        value
        dataSource
        _type
      }
      marketCap {
        _id
        value
        dataSource
        _type
      }
      description {
        _id
        value
        dataSource
        _type
      }
      ownership {
        _id
        value
        dataSource
        _type
      }
      tech {
        _id
        value
        dataSource
        _type
      }
      clearbitCompanyId {
        _id
        value
        dataSource
        _type
      }
      twitterHandle {
        _id
        value
        dataSource
        _type
      }
      facebookHandle {
        _id
        value
        dataSource
        _type
      }
      crunchbaseHandle {
        _id
        value
        dataSource
        _type
      }
      companyLinkedinUrl {
        _id
        value
        dataSource
        _type
      }
      discoverorgCompanyIds {
        _id
        value
        dataSource
        _type
      }
      zoominfoCompanyIds {
        _id
        value
        dataSource
        _type
      }
      legacyCompanyIds {
        _id
        value
        dataSource
        _type
      }
      phone {
        _id
        value
        dataSource
        _type
      }
      logo {
        _id
        value
        dataSource
        _type
      }
      googleRank {
        _id
        value
        dataSource
        _type
      }
      foundedBy {
        _id
        value
        dataSource
        _type
      }
      ticker {
        _id
        value
        dataSource
        _type
      }
      marketCap {
        _id
        value
        dataSource
        _type
      }
      timeZone {
        _id
        value
        dataSource
        _type
      }
      utcOffset {
        _id
        value
        dataSource
        _type
      }
    }
  }
`;
