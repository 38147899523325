export const CHANGE_CLICK_DIALER_DATA = 'CHANGE_CLICK_DIALER_DATA';
export const RESET_CLICK_DIALER_DATA = 'RESET_CLICK_DIALER_DATA';
export const initialValues = {
  sessionId: null,
  totalVmCount: 0,
  sessionStartTime: null,
  reportSessionId: null,
  clSessionId: null,
  talkerIsOnCall: false,
  isUnSavedCallDetailsExist: false,
  refreshThePage: false,
};

export const changeClickDialerData = (name, value) => {
  return { type: CHANGE_CLICK_DIALER_DATA, name, value };
};

export const resetClickDialerData = (name, value) => {
  const payload = { ...initialValues };
  if (name && value) {
    payload[name] = value;
    return { type: RESET_CLICK_DIALER_DATA, payload };
  } else {
    return { type: RESET_CLICK_DIALER_DATA, payload };
  }
};
