import gql from 'graphql-tag';

export const CREATE_SENDER = gql`
  mutation CreateSender(
    $firstName: String!
    $lastName: String!
    $email: String!
    $rolesMask: Int = 4
    $dailySendingLimit: Int = 50
    $userType: Int = 0
  ) {
    createuser(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        rolesMask: $rolesMask
        dailySendingLimit: $dailySendingLimit
        userType: $userType
      }
    ) {
      _id
    }
  }
`;

export default CREATE_SENDER;
