import gql from 'graphql-tag';

export const UPDATE_ACCOUNT_SELECTOR = gql`
  mutation UpdateAccountSelector(
    $accountSelectorArgs: AccountSelectorUpdateDTO!
    $selectorFilterCriteriaArgs: [SelectorFilterCriteriaUpdateDTO!]
  ) {
    updateAccountSelector(
      accountSelectorArgs: $accountSelectorArgs
      selectorFilterCriteriaArgs: $selectorFilterCriteriaArgs
    )
  }
`;

export default UPDATE_ACCOUNT_SELECTOR;
