/**
 * @author @Panimaya-Albert
 * @version V11.2
 */
import { useLazyQuery, useQuery } from '@apollo/client';
import { useToggle } from '@koncert/shared-components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { notify, showErrorMessage } from '../../../util';
import ClButton from '../../Common/Button';
import CloseButton from '../../Common/CloseButton';
import ConfirmModal from '../../Common/ConfirmModal';
import {
  ASSIGN_OR_MOVE_PROSPECT_TO_CADENCE_QUERY,
  FETCH_PROSPECT_QUERY,
  LOG_A_TASK_QUERY,
  LOG_CUSTOM_TASK_QUERY,
} from '../../queries/ProspectsQuery';
import CadenceList from './CadenceList';

type LogATaskModalProps = {
  showModal: boolean;
  hideModal: () => void;
  prospectId: number;
  currentUserId: number;
  selectUserId: number;
};

const LogATaskModal = (props: LogATaskModalProps) => {
  const {
    showModal,
    hideModal,
    prospectId,
    currentUserId,
    selectUserId,
  } = props;
  const [cadenceId, setCadenceId] = useState<null | number>(null);
  const [cadenceName, setCadenceName] = useState<null | string>(null);
  const [cadenceError, setCadenceError] = useToggle(false);
  const [prospectDetails, setProspectDetails] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useToggle(false);
  const [formValues, setFormValues] = useState({});
  const [isUserChanged, setIsUserChanged] = useState(false);
  const initialValues = {
    followupDate: null,
    subject: null,
    reminder: '08:00',
    notes: null,
    taskType: 'task',
    includeAddToCadence: false,
  };

  const getTimeString = (dateString: string) => {
    return moment(dateString).format('h:mm A');
  };

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: initialValues,
  });
  const showAddToCadence = watch('includeAddToCadence');

  useEffect(() => {
    setIsUserChanged(true);
  }, [selectUserId]);

  const { data } = useQuery(FETCH_PROSPECT_QUERY, {
    variables: {
      id: prospectId,
      currentUserId: selectUserId ? selectUserId : currentUserId,
      includeAssociationsQry: `includeAssociations[]=cadence`,
    },
    onCompleted: (response) => setProspectDetails(data?.prospect?.data[0]),
    onError: (response) => {
      showErrorMessage(
        response,
        'Failed to fetch prospect details',
        data,
        'failed_prospect_data_fetch'
      );
    },
  });

  const [
    createFolloupCall,
    { data: followupCallData, loading: followupCallLoading },
  ] = useLazyQuery(LOG_A_TASK_QUERY, {
    onCompleted: (response) =>
      handleCreateFollowUpRequestCallback(response, followupCallData, true),
    onError: (response) =>
      handleCreateFollowUpRequestCallback(response, followupCallData),
  });

  const [
    createFolloupTask,
    { data: followupTaskData, loading: followupTaskLoading },
  ] = useLazyQuery(LOG_CUSTOM_TASK_QUERY, {
    onCompleted: (response) =>
      handleCreateFollowUpRequestCallback(response, followupTaskData, true),
    onError: (response) =>
      handleCreateFollowUpRequestCallback(response, followupTaskData),
  });

  const [
    assignProspect,
    { data: assignProspectData, loading: assignProspectLoading },
  ] = useLazyQuery(ASSIGN_OR_MOVE_PROSPECT_TO_CADENCE_QUERY, {
    onCompleted: (response) =>
      handleAssignProspectRequestCallback(response, true),
    onError: (response) => handleAssignProspectRequestCallback(response),
  });

  const loading =
    followupTaskLoading || followupCallLoading || assignProspectLoading;

  const handleCreateFollowUpRequestCallback = (
    response,
    data,
    requestSuccess?: boolean
  ) => {
    if (requestSuccess) {
      notify('Task has been saved successfully!', 'success');
      hideModal();
    } else {
      showErrorMessage(
        response,
        'Sorry! Failed to save the task details.',
        data,
        'save_task_in_header'
      );
    }
  };

  const handleAssignProspectRequestCallback = (
    response,
    requestSuccess?: boolean
  ) => {
    if (requestSuccess) {
      notify('Prospect has been assigned successfully!', 'success');
      setShowConfirmModal(false);
    } else {
      showErrorMessage(
        response,
        'Sorry! Failed to assign the prospect in cadence.',
        assignProspectData,
        'assign_prospect_failed'
      );
    }
  };

  const handleSaveFollowup = (values) => {
    const { subject, followupDate, notes, reminder, taskType } = values;
    const input = {
      subject,
      notes,
      followupDate,
      reminder: getTimeString(followupDate + ' ' + reminder),
      prospect: { id: prospectId },
      taskType: taskType,
    };

    if (currentUserId !== selectUserId) {
      input['user'] = { id: selectUserId };
    }
    if (taskType === 'task') {
      createFolloupTask({
        variables: {
          input,
        },
      });
    } else {
      createFolloupCall({
        variables: {
          input,
        },
      });
    }
  };

  const onSubmit = (values) => {
    const { includeAddToCadence } = values;
    setFormValues(values);
    if (includeAddToCadence && !cadenceId) {
      setCadenceError(true);
      return;
    } else if (includeAddToCadence && cadenceId) {
      if (prospectDetails?.campaignName) {
        setShowConfirmModal(true);
        return;
      } else {
        const input = {};
        if (currentUserId !== selectUserId) {
          input['user'] = { id: selectUserId };
        }
        assignProspect({
          variables: {
            prospectId,
            cadenceId,
            input,
            action: 'assignToCadence',
          },
        });
      }
    }
    handleSaveFollowup(values);
  };
  const { ref: subjectRef, ...subject } = register('subject', {
    required: true, validate: { spaceValidation: (value) => { return !!value.trim(); }, },
  })
  const { ref: notesRef, ...notes } = register('notes', {required: true, validate: { spaceValidation: (value) => { return !!value.trim(); }, }, })
  const { ref: followupDateRef, ...followupDate } = register('followupDate', { required: 'Please select the valid due date' })
  const { ref: reminderRef, ...reminder } = register('reminder', { required: 'Please set the reminder time' })
  const { ref: includeAddToCadenceRef, ...includeAddToCadenceRest } = register('includeAddToCadence')
  const { ref: taskTypeRef, ...taskType } = register('taskType', { required: true })
  return (
    <Modal isOpen={showModal} centered>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <i className="fas fa-edit text-warning mr-2"></i>Set Followup
        </ModalHeader>
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <FormGroup>
            <Label for="subject">Subject</Label>
            <Input
              {...subject}
              type="text"
              name="subject"
              id="subject"
              maxLength={255}
              innerRef={subjectRef}
              invalid={errors.subject}
            />
            <FormFeedback>Subject is mandatory</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="notes">Notes</Label>
            <Input
              {...notes}
              type="textarea"
              name="notes"
              id="notes"
              innerRef={notesRef}
              maxLength={1000}
              invalid={errors.notes}
            />
            <FormFeedback>Notes are mandatory</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="follow_up_date">Due Date</Label>
            <Input
              {...followupDate}
              type="date"
              name="followupDate"
              id="follow_up_date"
              innerRef={followupDateRef}
              min={new Date().toISOString().substr(0, 10)}
              invalid={errors.followupDate}
            />
            <FormFeedback>Followup date is mandatory</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="reminder">Time</Label>
            <Input {...reminder} type="time" name="reminder" innerRef={reminderRef}></Input>
          </FormGroup>
          <FormGroup>
            <Label for="task_type">Task type</Label>
            <Input
              {...taskType}
              type="select"
              name="taskType"
              id="task_type"
              innerRef={taskTypeRef}
            >
              <option value="call">Call</option>
              <option value="task">Task</option>
            </Input>
          </FormGroup>
          <FormGroup check inline className="mb-2">
            <Label check>
              <Input
                {...includeAddToCadenceRest}
                type="checkbox"
                name="includeAddToCadence"
                innerRef={includeAddToCadenceRef}
              />
              Add to Cadence
            </Label>
          </FormGroup>
          {showAddToCadence && (
            <FormGroup>
              <Label for="cadence">Cadence</Label>
              <CadenceList
                value={cadenceId}
                onChange={(value: number, name: string) => {
                  setCadenceId(value);
                  setCadenceName(name);
                }}
                placeHolder="Select Cadence"
                currentUserId={selectUserId ? selectUserId : currentUserId}
                isUserChanged={isUserChanged}
                setIsUserChanged={setIsUserChanged}
              />
              <p className="text-danger text-sm">
                {cadenceError && 'Please select a Cadence'}
              </p>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <CloseButton btnTxt="Cancel" onClick={() => hideModal()} />
          <ClButton
            color="primary"
            icon={loading ? 'fa fa-spinner fa-spin' : 'fa fa-check'}
            title="Save"
            disabled={loading}
            type="submit"
          >
            Save
          </ClButton>
        </ModalFooter>
      </Form>
      {showConfirmModal && (
        <ConfirmModal
          showConfirmModal={showConfirmModal}
          handleCancel={() => setShowConfirmModal()}
          handleConfirm={() => {
            const input = {};
            if (currentUserId !== selectUserId) {
              input['userId'] = selectUserId;
            }
            assignProspect({
              variables: {
                prospectId,
                cadenceId,
                input,
                action: 'moveToCadence',
              },
            });
            handleSaveFollowup(formValues);
          }}
          showConfirmBtnSpinner={loading && assignProspectLoading}
          confirmBtnText="OK"
          confirmBtnIcon="fas fa-check"
          otherBtnRequired={true}
          otherBtnAlign="left"
          otherBtnColor="secondary"
          otherBtnHandler={() => handleSaveFollowup(formValues)}
          otherBtnText="Cancel"
          otherBtnIcon={
            loading && !assignProspectLoading
              ? 'fas fa-spinner fa-spin'
              : 'fas fa-times'
          }
        >
          The prospect is already in cadence{' '}
          <strong>{prospectDetails?.campaignName}</strong>, do you still want to
          move this prospect to Cadence <strong>{cadenceName}</strong>?.
          <br />
          <br />
          Click OK to continue or click cancel to skip from moving to another
          cadence.
        </ConfirmModal>
      )}
    </Modal>
  );
};

export default LogATaskModal;