import gql from 'graphql-tag';

export const EVENTS_LOGS_COUNT_QUERY = gql`
  query eventLogsCount($filter: EventLogQueryArgs!) {
    eventLogsMeta(where: $filter) {
      count
    }
  }
`;
export const EVENT_LOGS_QUERY = gql`
  query EVENT_LOGS_QUERY(
    $filter: EventLogQueryArgs!
    $skip: Int = 0
    $limit: Int = 100
  ) {
    eventLogs(
      order: { createdAt: DESC }
      where: $filter
      skip: $skip
      limit: $limit
    ) {
      _id
      createdAt
      level
      event
      outcome
      path
      lineno
      label
      message
      user {
        _id
        email
      }
      customer {
        _id
        name
      }
      data
      backtrace
    }
  }
`;
