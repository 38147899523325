import gql from 'graphql-tag';

export const STORIES_QUERY = gql`
  query v3_Customer_Stories(
    $filter: StoryQueryArgs! = {}
    $limit: Int = 10
    $skip: Int = 0
    $order: StoryQueryArgsSort = {}
  ) {
    stories(where: $filter, limit: $limit, skip: $skip, order: $order) {
      _id
      name
      priority
      pausedAt
      accountSelectorId
      contactSelectorId
      rulesOfEngagementId
      sendingWindowDayStart
      sendingWindowDayEnd
      sendingWindowHourStart
      sendingWindowHourEnd
      matchingAccountsCount
      matchingContactsCount
      storyContactsCount
    }
    storiesMeta(where: $filter) {
      count
    }
  }
`;

export const DATAPOINT_TYPES_WITH_DATASOURCES = gql`
  query masterDatapoints {
    findAllMasterDatapoints {
      name
      datasource
    }
  }
`;
